import { useState } from 'react';
import { FaCheckCircle, FaTimesCircle, FaQuestionCircle, FaRegSquare, FaSpinner } from 'react-icons/fa';
import AudioStreamer, { EvaluationResponse, Task } from './AudioStreamer';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { RESTAURANT_BACKEND_HOST } from './RestaurantBackend';
import { Summary } from '../../types/Summary';

const RestaurantAudio: React.FC = () => {
  const [finalTranscriptParts, setFinalTranscriptParts] = useState<string[]>([]);
  const [interimTranscript, setInterimTranscript] = useState<string>('');
  const [evaluation, setEvaluation] = useState<Task[]>([]);
  const [isFinalEvaluation, setIsFinalEvaluation] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [summary, setSummary] = useState<Summary | null>(null);
  const websocketUrl = `wss://${RESTAURANT_BACKEND_HOST}/ws/audio?client_type=web&server_id=1`;

  const handleInterimTranscript = (text: string) => {
    setInterimTranscript(text);
  };

  const handleFinalTranscript = (text: string) => {
    setInterimTranscript('');
    setFinalTranscriptParts(prev => [...prev, text]);
  };

  const handleEvaluation = (data: EvaluationResponse, isFinal: boolean, summary?: Summary) => {
    if (isFinal) {
      setIsFinalEvaluation(true);
      setEvaluation(data.tasks);
      setSummary(summary || null);
      setIsProcessing(false);
    } else {
      setIsFinalEvaluation(false);
      setEvaluation(data.tasks);
    }
  };

  const handleStartRecording = () => {
    setFinalTranscriptParts([]);
    setInterimTranscript('');
    setEvaluation([]);
    setIsFinalEvaluation(false);
    setIsProcessing(false);
  };

  const handleStopRecording = () => {
    setIsProcessing(true);
  };

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-6">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          Restaurant Script Validation
        </h2>

        <div className="mb-4 text-sm text-gray-600">
          Connected to: {websocketUrl}
        </div>

        {/* Recording Control */}
        <div className="mb-6">
          <AudioStreamer
            onInterimTranscript={handleInterimTranscript}
            onFinalTranscript={handleFinalTranscript}
            onEvaluation={handleEvaluation}
            onStart={handleStartRecording}
            onStop={handleStopRecording}
            websocketUrl={websocketUrl}
          />
        </div>

        {/* Live Transcript */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold text-gray-700 mb-2">
            Live Transcript
          </h3>
          <div className="bg-gray-50 rounded-lg p-4 min-h-[100px] max-h-[200px] overflow-y-auto">
            {finalTranscriptParts.map((part, index) => (
              <span key={index}>{part} </span>
            ))}
            {interimTranscript && (
              <span className="text-gray-500">{interimTranscript}</span>
            )}
            {finalTranscriptParts.length === 0 && !interimTranscript && (
              'Transcript will appear here...'
            )}
          </div>
        </div>

        {/* Task Checklist */}
        <div>
          <h3 className="text-lg font-semibold text-gray-700 mb-2">
            Required Tasks
            {isProcessing && (
              <FaSpinner className="inline ml-2 animate-spin text-blue-500" />
            )}
          </h3>
          <div className="bg-gray-50 rounded-lg p-4">
            {evaluation.length === 0 ? (
              <p className="text-gray-500">Tasks will appear here as you speak...</p>
            ) : (
              <ul className="space-y-3">
                {evaluation.map((task, index) => (
                  <li
                    key={index}
                    className="flex items-center gap-3 p-2 bg-white rounded shadow-sm"
                  >
                    {task.done ? (
                      <FaCheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                    ) : isFinalEvaluation ? (
                      <FaTimesCircle className="w-5 h-5 text-red-500 flex-shrink-0" />
                    ) : (
                      <FaRegSquare className="w-5 h-5 text-gray-400 flex-shrink-0" />
                    )}
                    <span className="flex-grow">{task.task}</span>
                    <FaQuestionCircle
                      className="w-4 h-4 text-gray-400 cursor-help"
                      data-tooltip-id={`tooltip-${index}`}
                      data-tooltip-content={task.reason}
                    />
                    <Tooltip id={`tooltip-${index}`} />
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {isFinalEvaluation && summary && (
          <div className="mt-6">
            <h3 className="text-lg font-semibold text-gray-700 mb-2">
              Performance Summary
            </h3>
            <div className="bg-gray-50 rounded-lg p-4 space-y-4">
              {summary.well && summary.well.length > 0 && (
                <div>
                  <h4 className="font-medium text-green-600 mb-2">Done Well:</h4>
                  <ul className="list-disc list-inside space-y-1">
                    {summary.well.map((item, index) => (
                      <li key={index} className="text-gray-700">{item}</li>
                    ))}
                  </ul>
                </div>
              )}
              {summary.better && summary.better.length > 0 && (
                <div>
                  <h4 className="font-medium text-blue-600 mb-2">Could Be Better:</h4>
                  <ul className="list-disc list-inside space-y-1">
                    {summary.better.map((item, index) => (
                      <li key={index} className="text-gray-700">{item}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RestaurantAudio;