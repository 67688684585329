import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { RESTAURANT_BACKEND_HOST } from '../RestaurantBackend';
import FullPageSpinner from '../../FullPageSpinner';
import './ServerManagement.css'; // Import the CSS for toggle styling
import { parseISO, format } from 'date-fns';

// Define shift type
interface Shift {
  id: string;
  server_id: string;
  server_name: string;
  started: string;
  fragments: number;
  has_notifications: boolean | number;
  notes: string | null;
}

const RecentShiftsTable: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Get pagination and filter parameters from URL
  const initialMinFragments = parseInt(searchParams.get('minFragments') || '100', 10);
  const initialPage = parseInt(searchParams.get('page') || '1', 10);

  const [minFragments, setMinFragments] = useState<number>(initialMinFragments);
  const [shifts, setShifts] = useState<Shift[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [hasNextPage, setHasNextPage] = useState(false);
  const pageSize = 100;

  // Helper function to check if notifications are enabled
  const isNotificationsEnabled = (value: boolean | number): boolean => {
    return value === true || value === 1;
  };

  const handleMinFragmentsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    const newMinFragments = isNaN(value) ? 0 : value;
    setMinFragments(newMinFragments);

    // Update URL
    const params = new URLSearchParams(location.search);
    params.set('minFragments', newMinFragments.toString());
    params.set('page', '1'); // Reset to page 1 when filters change
    navigate(`${location.pathname}?${params.toString()}`);

    // Also update state
    setCurrentPage(1);
  };

  // Fetch shifts data
  useEffect(() => {
    const fetchShifts = async () => {
      setIsLoading(true);
      try {
        const shiftsQuery = `
          SELECT 
            s.id, 
            s.server_id, 
            sv.name as server_name,
            CONVERT_TZ(s.created, 'UTC', 'US/Pacific') as started, 
            count(*) as fragments,
            if(s.has_notifications is not null, s.has_notifications, exists(select 1 from shift_event se where se.shift_id = s.id and se.event_type = 'notification')) as has_notifications,
            s.notes
          FROM shift s 
          LEFT OUTER JOIN transcript_part tp ON tp.shift_id = s.id
          LEFT OUTER JOIN server sv ON s.server_id = sv.id
          GROUP BY 1, 2, 3, 4
          HAVING count(*) >= ${minFragments}
          ORDER BY 4 DESC
          LIMIT ${pageSize + 1} OFFSET ${(currentPage - 1) * pageSize}
        `;

        const response = await fetch(
          `https://${RESTAURANT_BACKEND_HOST}/admin/execute-select?sql=${encodeURIComponent(shiftsQuery)}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch shifts');
        }

        const data = await response.json();
        const fetchedShifts = data.results;

        // Check if there's a next page
        setHasNextPage(fetchedShifts.length > pageSize);

        // Store only pageSize shifts
        setShifts(fetchedShifts.slice(0, pageSize));
      } catch (error) {
        console.error('Error fetching shifts:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchShifts();
  }, [minFragments, currentPage]);

  // Update URL when page changes via the URL
  useEffect(() => {
    const urlPage = parseInt(searchParams.get('page') || '1', 10);
    const urlMinFragments = parseInt(searchParams.get('minFragments') || '100', 10);

    if (urlPage !== currentPage) {
      setCurrentPage(urlPage);
    }

    if (urlMinFragments !== minFragments) {
      setMinFragments(urlMinFragments);
    }
  }, [location.search, searchParams]);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1) {
      // Update URL
      const params = new URLSearchParams(location.search);
      params.set('page', newPage.toString());
      navigate(`${location.pathname}?${params.toString()}`);

      // Also update state
      setCurrentPage(newPage);
      window.scrollTo(0, 0);
    }
  };

  const handleShiftClick = (shiftId: string, serverId: string) => {
    navigate(`/restaurant/shifts/${shiftId}/transcript/${serverId}`);
  };

  const handleToggleNotifications = async (shiftId: string, currentValue: boolean) => {
    try {
      const response = await fetch(`https://${RESTAURANT_BACKEND_HOST}/shifts/${shiftId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ has_notifications: !currentValue }),
      });

      if (!response.ok) {
        throw new Error('Failed to update notifications status');
      }

      // Update local state
      setShifts(shifts.map(shift =>
        shift.id === shiftId
          ? { ...shift, has_notifications: !currentValue }
          : shift
      ));

      console.log('Notifications status updated successfully');
    } catch (error) {
      console.error('Error updating notifications status:', error);
      alert('Failed to update notifications status. Please try again.');
    }
  };

  const handleUpdateNotes = async (shiftId: string, notes: string) => {
    try {
      const response = await fetch(`https://${RESTAURANT_BACKEND_HOST}/shifts/${shiftId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ notes }),
      });

      if (!response.ok) {
        throw new Error('Failed to update notes');
      }

      // Update local state
      setShifts(shifts.map(shift =>
        shift.id === shiftId
          ? { ...shift, notes }
          : shift
      ));

      console.log('Notes updated successfully');
    } catch (error) {
      console.error('Error updating notes:', error);
      alert('Failed to update notes. Please try again.');
    }
  };

  // Helper function to format date
  const formatDate = (dateString: string): string => {
    try {
      const date = parseISO(dateString);
      return format(date, 'MMM d, yyyy h:mm a');
    } catch (error) {
      return dateString;
    }
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Recent Server Shifts</h1>
        <div className="flex space-x-2">
          <Link
            to="/restaurant/servers"
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
          >
            Manage Servers
          </Link>
          <Link
            to="/restaurant"
            className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
          >
            Back to Dashboard
          </Link>
        </div>
      </div>
      <div className="mb-4 flex items-center">
        <label htmlFor="minFragments" className="mr-2 font-medium">
          Minimum Fragments:
        </label>
        <input
          id="minFragments"
          type="number"
          min="0"
          value={minFragments}
          onChange={handleMinFragmentsChange}
          className="px-3 py-1 border border-gray-300 rounded-md"
        />
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200 table-fixed">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/5">Server</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">Started</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-20">Fragments</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-24">Notifications Were Sent</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Notes</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {shifts.map((shift) => (
              <tr key={shift.id} className="hover:bg-gray-50">
                <td
                  className="px-6 py-4 text-sm cursor-pointer"
                  onClick={() => handleShiftClick(shift.id, shift.server_id)}
                >
                  <div className="font-medium text-blue-600 hover:text-blue-800">{shift.server_name}</div>
                  <div className="text-xs text-gray-500 mt-1 truncate" title={shift.id}>{shift.id}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {formatDate(shift.started)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500">
                  {shift.fragments}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div className="flex items-center justify-center">
                    <div className="relative inline-block w-10 mr-2 align-middle select-none">
                      <input
                        type="checkbox"
                        id={`notifications-${shift.id}`}
                        className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                        checked={isNotificationsEnabled(shift.has_notifications)}
                        onChange={() => handleToggleNotifications(shift.id, isNotificationsEnabled(shift.has_notifications))}
                      />
                      <label
                        htmlFor={`notifications-${shift.id}`}
                        className={`toggle-label block overflow-hidden h-6 rounded-full cursor-pointer ${isNotificationsEnabled(shift.has_notifications) ? 'bg-blue-500' : 'bg-gray-300'
                          }`}
                      ></label>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  <textarea
                    className="w-full border border-gray-300 rounded p-2 text-sm"
                    defaultValue={shift.notes || ''}
                    rows={2}
                    placeholder="Add notes..."
                    onBlur={(e) => {
                      const newValue = e.target.value;
                      if (newValue !== shift.notes) {
                        handleUpdateNotes(shift.id, newValue);
                      }
                    }}
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div className="flex space-x-2">
                    <Link
                      to={`/restaurant/shifts/${shift.id}/transcript/${shift.server_id}`}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      View Transcript
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-4 flex justify-between items-center">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
        >
          Previous
        </button>
        <span>Page {currentPage}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={!hasNextPage}
          className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default RecentShiftsTable; 