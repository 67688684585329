import React, { useState } from 'react';
import GradientBespoke from './GradientBespoke';
import FullPageSpinner from './FullPageSpinner';
import api from '../api';

const OldLogin: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const handleGoogleLogin = async (): Promise<void> => {
    setLoading(true);
    window.location.href = await api.getGoogleAuthorizationUrl()
  };

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <div className="min-h-screen flex flex-col">
      {/* Top section with skin background */}
      <div className="bg-gradient-to-b from-skin-200 to-skin-400 flex-grow pb-16">
        <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12 py-8 sm:py-12">
          <div className="flex flex-col md:flex-row md:items-stretch">
            <div className="md:w-1/2 md:pr-8 flex flex-col">
              <div>
                <div className="flex items-center mb-6">
                  <img className="h-12 sm:h-16 w-auto mr-4" src="img/main-logo.png" alt="Bespoke logo" />
                  <div className="text-left ml-2">
                    <GradientBespoke />
                  </div>
                </div>
                <p className="mt-4 text-xl sm:text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-bespokePurple via-bespokePink to-bespokeOrange">
                  Transform Workplace Feedback Into Meaningful Conversations
                </p>
                <p className="mt-6 text-base sm:text-lg leading-7 sm:leading-8 text-gray-700">
                  Giving effective feedback is challenging, but it doesn't have to be. Bespoke's AI-guided interview process helps you articulate thoughtful, constructive feedback for your colleagues, direct reports, and managers.

                  Let our AI guide you through structured conversations to deliver impactful feedback that drives growth and strengthens relationships.
                </p>
              </div>
              <div className="flex-grow flex flex-col justify-center min-h-[100px] pt-8">
                <div className="flex justify-center">
                  <button
                    onClick={handleGoogleLogin}
                    className="flex items-center px-6 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-100 active:bg-gray-200 active:shadow-inner transition duration-150 ease-in-out shadow-md"
                  >
                    <img src="img/google.png" alt="Google Logo" className="w-6 h-6 mr-4" />
                    <span className="font-semibold">Sign up / Log in with Google</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 mt-8 md:mt-0 flex items-center">
              <img src="img/feedback.webp" alt="People giving feedback" className="w-full h-auto rounded-lg shadow-lg" />
            </div>
          </div>
        </div>
      </div>

      {/* Lower sections overlapping with top region */}
      <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12 -mt-16">
        <div className="bg-gradient-to-br from-skin-50 to-skin-100 rounded-lg shadow-lg overflow-hidden">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-8">
            {/* Give Feedback */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl sm:text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-bespokePurple to-bespokePink mb-4">Give Better Feedback</h2>
              <p className="text-base sm:text-lg text-gray-700 mb-4">
                Our AI interviewer guides you through a structured process to deliver clear, constructive, and actionable feedback that helps people grow.
              </p>
              <h3 className="text-lg sm:text-xl font-semibold text-bespokePurple mb-2">Feedback Types</h3>
              <ul className="list-disc list-inside text-sm sm:text-base text-gray-600 space-y-1">
                <li>Performance Reviews</li>
                <li>Peer Feedback</li>
                <li>Manager Feedback</li>
                <li>Self Assessments</li>
                <li>360° Reviews</li>
              </ul>
            </div>

            {/* Request & Manage Feedback */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl sm:text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-bespokePink to-bespokeOrange mb-4">Request & Share Feedback</h2>
              <p className="text-base sm:text-lg text-gray-700 mb-4">
                Easily request feedback from your network and manage all your feedback interactions in one place. Build a culture of continuous improvement.
              </p>
              <h3 className="text-lg sm:text-xl font-semibold text-bespokeOrange mb-2">Key Features</h3>
              <ul className="list-disc list-inside text-sm sm:text-base text-gray-600 space-y-1">
                <li>Request Feedback</li>
                <li>Track Feedback Progress</li>
                <li>Share Feedback History</li>
                <li>Set Feedback Goals</li>
                <li>Feedback Analytics</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Founders Section */}
        <div className="mt-12 bg-gradient-to-br from-skin-50 to-skin-100 rounded-lg shadow-lg overflow-hidden">
          <div className="p-8">
            <h2 className="text-2xl font-bold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-bespokePurple to-bespokePink">
              Meet the Founders
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {/* Eric Chung - CEO */}
              <div className="flex flex-col items-center text-center p-6 bg-white rounded-lg shadow-md">
                <img
                  src="img/eric.png"
                  alt="Eric Chung"
                  className="w-32 h-32 rounded-full object-cover mb-4 border-2 border-bespokePurple"
                />
                <h3 className="text-xl font-bold text-gray-900">Eric Chung</h3>
                <p className="text-bespokePurple font-semibold mb-2">CEO & Co-founder</p>
                <p className="text-gray-600 max-w-sm">
                  Former Coinbase executive, lawyer, and professional poker player. Passionate about transforming workplace communication and
                  helping teams build stronger relationships through meaningful feedback.
                </p>
              </div>

              {/* Jesse Hull - CTO */}
              <div className="flex flex-col items-center text-center p-6 bg-white rounded-lg shadow-md">
                <img
                  src="img/jesse.png"
                  alt="Jesse Hull"
                  className="w-32 h-32 rounded-full object-cover mb-4 border-2 border-bespokePurple"
                />
                <h3 className="text-xl font-bold text-gray-900">Jesse Hull</h3>
                <p className="text-bespokePurple font-semibold mb-2">CTO & Co-founder</p>
                <p className="text-gray-600 max-w-sm">
                  Serial entrepreneur and engineering executive with extensive experience in big data, AI, and driving technological innovation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-white mt-6 mb-6">
        {/* Terms and Privacy links */}
        <div className="bottom-0 left-0 w-full p-2 text-center text-xs">
          <a href="/privacy.html" className="text-gray-500 hover:text-gray-700">Privacy</a>
          <span className="mx-1 text-gray-500">|</span>
          <a href="/terms.html" className="text-gray-500 hover:text-gray-700">Terms</a>
          <span className="mx-1 text-gray-500">|</span>
          <a href="/restaurant" className="text-gray-500 hover:text-gray-700">Restaurant Demo</a>
          <span className="mx-1 text-gray-500">|</span>
          <a href="/restaurant_audio" className="text-gray-500 hover:text-gray-700">Restaurant Audio Demo</a>
        </div>
      </footer>
    </div>
  );
};

export default OldLogin;