import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FullPageSpinner from '../../FullPageSpinner';
import { RESTAURANT_BACKEND_HOST } from '../RestaurantBackend';
import './ServerManagement.css';

interface Server {
  id: string;
  name: string;
  location_id: string;
  location_name: string;
  features: any;
  demo: boolean;
  test: boolean;
}

const ServerManagementView: React.FC = () => {
  const [servers, setServers] = useState<Server[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<string>('');
  const [locations, setLocations] = useState<{ id: string, name: string }[]>([]);

  // Fetch servers from the backend
  useEffect(() => {
    const fetchServers = async () => {
      setIsLoading(true);
      try {
        const sql = `
          SELECT 
            server.id, 
            server.name, 
            server.location_id,
            server.features,
            server.demo,
            server.test,
            location.name as location_name
          FROM server
          JOIN location ON server.location_id = location.id
          ORDER BY server.name ASC
        `;

        const response = await fetch(
          `https://${RESTAURANT_BACKEND_HOST}/admin/execute-select?sql=${encodeURIComponent(sql)}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch servers');
        }

        const data = await response.json();
        setServers(data.results);

        // Extract unique locations
        const uniqueLocations = Array.from(
          new Set(data.results.map((server: Server) => server.location_id))
        ).map(locationId => {
          const server = data.results.find((s: Server) => s.location_id === locationId);
          return {
            id: locationId as string,
            name: server?.location_name || 'Unknown'
          };
        });

        setLocations(uniqueLocations);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An unknown error occurred');
        console.error('Error fetching servers:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchServers();
  }, []);

  // Function to toggle notifications for a server
  const toggleNotifications = async (serverId: string, currentStatus: boolean) => {
    try {
      // Create the new features object
      const newFeatures = { notifications: !currentStatus };

      const response = await fetch(
        `https://${RESTAURANT_BACKEND_HOST}/servers/${serverId}/features`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ features: newFeatures }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update server notifications');
      }

      const data = await response.json();

      // Update the local state
      setServers(servers.map(server =>
        server.id === serverId
          ? {
            ...server,
            features: data.server.features
          }
          : server
      ));

    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
      console.error('Error updating server notifications:', err);
    }
  };

  // Function to check if notifications are enabled for a server
  const isNotificationsEnabled = (server: Server): boolean => {
    if (!server.features) return false;

    try {
      const features = typeof server.features === 'string'
        ? JSON.parse(server.features)
        : server.features;

      return features?.notifications === true;
    } catch (e) {
      return false;
    }
  };

  // Filter servers based on search query and selected location
  const filteredServers = servers.filter(server => {
    const matchesSearch = server.name.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesLocation = selectedLocation === '' || server.location_id === selectedLocation;
    return matchesSearch && matchesLocation;
  });

  if (isLoading) {
    return <FullPageSpinner />;
  }

  if (error) {
    return <div className="text-red-500 p-4">Error: {error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-5xl">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Server Management</h1>
        <Link
          to="/restaurant/shifts"
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
        >
          Back to Shifts
        </Link>
      </div>

      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mb-6">
          <div className="w-full md:w-1/2">
            <label htmlFor="search" className="block text-sm font-medium text-gray-700 mb-1">
              Search Servers
            </label>
            <input
              type="text"
              id="search"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Search by name..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="w-full md:w-1/2">
            <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">
              Filter by Location
            </label>
            <select
              id="location"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e.target.value)}
            >
              <option value="">All Locations</option>
              {locations.map(location => (
                <option key={location.id} value={location.id}>
                  {location.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Location
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Type
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Notifications
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredServers.map(server => (
                <tr key={server.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {server.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {server.location_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {server.demo ? (
                      <span className="bg-purple-100 text-purple-800 px-2 py-1 rounded-full text-xs">Demo</span>
                    ) : server.test ? (
                      <span className="bg-yellow-100 text-yellow-800 px-2 py-1 rounded-full text-xs">Test</span>
                    ) : (
                      <span className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-xs">Regular</span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div className="flex items-center">
                      <label htmlFor={`notifications-${server.id}`} className="mr-2 text-sm">
                        {isNotificationsEnabled(server) ? 'Enabled' : 'Disabled'}
                      </label>
                      <div className="relative inline-block w-10 mr-2 align-middle select-none">
                        <input
                          type="checkbox"
                          id={`notifications-${server.id}`}
                          className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                          checked={isNotificationsEnabled(server)}
                          onChange={() => toggleNotifications(server.id, isNotificationsEnabled(server))}
                        />
                        <label
                          htmlFor={`notifications-${server.id}`}
                          className={`toggle-label block overflow-hidden h-6 rounded-full cursor-pointer ${isNotificationsEnabled(server) ? 'bg-blue-500' : 'bg-gray-300'
                            }`}
                        ></label>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ServerManagementView; 