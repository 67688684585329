import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import FullPageSpinner from '../../FullPageSpinner';
import { RESTAURANT_BACKEND_HOST } from '../RestaurantBackend';
import { FaPlay, FaPause, FaChartBar, FaCalendarAlt } from 'react-icons/fa';

interface TranscriptPart {
  id: string;
  start_time: number;
  text: string;
  speaker: string;
  created: string;
}

// Add ShiftEvent interface
interface ShiftEvent {
  id: string;
  event_type: string;
  event_data: any;
  created: string;
}

// Create a combined item type for sorting transcripts and events together
interface TimelineItem {
  type: 'transcript' | 'event';
  data: TranscriptPart | ShiftEvent;
  created: string;
}

interface Recording {
  url: string;
  key: string;
  size: number;
  last_modified: string;
  duration?: number;
}

interface ShiftInteraction {
  id: string;
  start_time: number;
  end_time: number;
  interaction_type: string;
  data: any;
  created: string;
  correct_human_verified: boolean | null;
}

interface ServiceCriterion {
  type: string;
  fulfilled: boolean;
}

interface ShiftDetails {
  serverName: string;
  shiftCreated: string;
  shiftEnded: string | null;
  deviceInfo?: {
    client_type: string;
    device_name?: string;
    device_model?: string;
    system_name?: string;
    system_version?: string;
    app_version?: string;
  };
}

// Add the CriteriaStats interface from ShiftPerformanceView
interface CriteriaStats {
  criterionType: string;
  fulfilled: number;
  total: number;
  percentage: number;
}

const ShiftTranscriptView: React.FC = () => {
  const { shiftId, serverId } = useParams<{ shiftId: string; serverId: string }>();
  const [transcriptParts, setTranscriptParts] = useState<TranscriptPart[]>([]);
  const [recordings, setRecordings] = useState<Recording[]>([]);
  const [interactions, setInteractions] = useState<ShiftInteraction[]>([]);
  // Add state for shift events
  const [shiftEvents, setShiftEvents] = useState<ShiftEvent[]>([]);
  // Add state for combined timeline items
  const [timelineItems, setTimelineItems] = useState<TimelineItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentRecordingIndex, setCurrentRecordingIndex] = useState(0);
  const [shiftDetails, setShiftDetails] = useState<ShiftDetails | null>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const transcriptRef = useRef<HTMLDivElement>(null);
  const [showVerifiedOnly, setShowVerifiedOnly] = useState(true);

  // New states for audio loading
  const [audioLoadingState, setAudioLoadingState] = useState<'idle' | 'loading' | 'complete'>('idle');
  const [audioLoadingProgress, setAudioLoadingProgress] = useState(0);
  const [totalAudioDuration, setTotalAudioDuration] = useState(0);
  const [audioDurationMap, setAudioDurationMap] = useState<{ [startTime: number]: { fileIndex: number, offsetInFile: number } }>({});

  // Hidden audio element for measuring duration
  const hiddenAudioRef = useRef<HTMLAudioElement | null>(null);

  // Criteria types we're interested in (copied from ShiftPerformanceView)
  const criteriaTypes = [
    "upsell_premium_menu",
    "mention_specific_premium_item",
    "describe_specific_premium_item"
  ];

  // Friendly names for criteria types (copied from ShiftPerformanceView)
  const criteriaLabels: Record<string, string> = {
    "upsell_premium_menu": "Upsell Premium Menu",
    "mention_specific_premium_item": "Mention Specific Premium Item",
    "describe_specific_premium_item": "Describe Specific Premium Item"
  };

  // Calculate first transcript part ID for each interaction
  const getFirstTranscriptPartForInteraction = (interaction: ShiftInteraction): string | null => {
    const matchingParts = transcriptParts.filter(part =>
      part.start_time >= interaction.start_time &&
      part.start_time <= interaction.end_time
    );
    return matchingParts.length > 0 ? matchingParts[0].id : null;
  };

  const buildFeedbackDisplay = (data: any): string => {
    const orderedCriteria = [
      "upsell_premium_menu",
      "mention_specific_premium_item",
      "describe_specific_premium_item"
    ];

    const criteriaMap = {
      "upsell_premium_menu": "💰",
      "mention_specific_premium_item": "🥩",
      "describe_specific_premium_item": "🗣️"
    };

    const serviceCriteria = (data?.service_criteria || []) as ServiceCriterion[];
    const criteriaStatus = Object.fromEntries(
      serviceCriteria.map((criterion: ServiceCriterion) => [criterion.type, criterion.fulfilled || false])
    );

    return orderedCriteria
      .map(criterionType => {
        const emoji = criteriaMap[criterionType as keyof typeof criteriaMap];
        const status = criteriaStatus[criterionType] ? "✅" : "➖";
        return `${emoji}${status}`;
      })
      .join("  ");
  };

  const formatTimestamp = (seconds: number): string => {
    return `${seconds.toFixed(1)}s`;
  };

  const isTimeInAnyInteraction = (time: number): boolean => {
    return interactions.some(
      interaction =>
        time >= interaction.start_time &&
        time <= interaction.end_time
    );
  };

  const getInteractionsForTime = (time: number): ShiftInteraction[] => {
    return interactions.filter(
      interaction =>
        time >= interaction.start_time &&
        time <= interaction.end_time
    );
  };

  const getNextInteraction = (currentStartTime: number): ShiftInteraction | null => {
    const sortedInteractions = [...interactions].sort((a, b) => a.start_time - b.start_time);
    const nextInteraction = sortedInteractions.find(interaction => interaction.start_time > currentStartTime);
    return nextInteraction || null;
  };

  const handleNextInteraction = (currentStartTime: number) => {
    const nextInteraction = getNextInteraction(currentStartTime);
    if (nextInteraction) {
      const element = document.querySelector(`[data-time="${nextInteraction.start_time}"]`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      if (audioRef.current) {
        audioRef.current.currentTime = nextInteraction.start_time;
      }
    }
  };

  // Function to load and measure audio durations
  const loadAudioDurations = async () => {
    if (!recordings.length) return;

    setAudioLoadingState('loading');

    // Create a hidden audio element if it doesn't exist
    if (!hiddenAudioRef.current) {
      const audio = document.createElement('audio');
      audio.style.display = 'none'; // Keep it hidden
      // Use a unique ID or other method if multiple instances might exist
      audio.id = `hidden-audio-measure-${shiftId}`;
      // Append somewhere stable, like the body, but ensure cleanup
      document.body.appendChild(audio);
      hiddenAudioRef.current = audio;
    }

    const updatedRecordings = [...recordings];
    const measuredDurations: (number | null)[] = new Array(updatedRecordings.length).fill(null);
    let previousProgress = -1;
    const batchSize = 10;
    let filesProcessed = 0;

    // 1. Measure durations in batches
    for (let i = 0; i < updatedRecordings.length; i += batchSize) {
      const batch = updatedRecordings.slice(i, i + batchSize);
      const batchPromises = batch.map((recording, indexInBatch) => {
        // Create a *new* audio element for each concurrent request
        const tempAudio = document.createElement('audio');
        return getAudioDuration(recording.url, tempAudio) // Pass the element
          .then(duration => {
            const originalIndex = i + indexInBatch;
            console.log(`Audio file ${originalIndex}: ${recording.key}, Duration: ${duration}s`);
            updatedRecordings[originalIndex].duration = duration;
            measuredDurations[originalIndex] = duration;
          })
          .catch(err => {
            const originalIndex = i + indexInBatch;
            console.error(`Error loading audio file ${originalIndex} (${recording.key}):`, err);
            measuredDurations[originalIndex] = 0; // Assign 0 on error
          });
      });

      // Wait for the current batch to complete
      await Promise.all(batchPromises);

      filesProcessed += batch.length;
      // Update progress after each batch
      const currentProgress = Math.round((filesProcessed / updatedRecordings.length) * 100);
      if (currentProgress > previousProgress) {
        setAudioLoadingProgress(currentProgress);
        previousProgress = currentProgress;
      }
    }

    // Clean up the main hidden audio ref if it was created
    if (hiddenAudioRef.current && document.body.contains(hiddenAudioRef.current)) {
      hiddenAudioRef.current.remove();
      hiddenAudioRef.current = null;
    }

    // Filter out any nulls from measuredDurations (shouldn't happen with error handling, but good practice)
    const validDurations = measuredDurations.filter(d => d !== null) as number[];

    // 2. Calculate cumulative durations
    const cumulativeDurations: number[] = [];
    let currentCumulative = 0;
    for (const duration of validDurations) {
      currentCumulative += duration;
      cumulativeDurations.push(currentCumulative);
    }
    const totalDuration = currentCumulative;
    console.log('Cumulative durations:', cumulativeDurations);

    // 3. Build the duration map using cumulative durations
    const durationMap: { [startTime: number]: { fileIndex: number, offsetInFile: number } } = {};
    transcriptParts.forEach(part => {
      let fileIndex = -1;
      let offsetInFile = -1;

      // Find the correct file index and offset
      for (let i = 0; i < cumulativeDurations.length; i++) {
        const previousCumulative = i === 0 ? 0 : cumulativeDurations[i - 1];
        const currentFileEnd = cumulativeDurations[i];

        if (part.start_time >= previousCumulative && part.start_time < currentFileEnd) {
          fileIndex = i;
          offsetInFile = part.start_time - previousCumulative;
          break; // Found the correct segment
        }
      }

      // Handle cases where start_time might exactly match the last cumulative duration (edge case)
      if (fileIndex === -1 && part.start_time === totalDuration && totalDuration > 0) {
        fileIndex = cumulativeDurations.length - 1; // Assign to the last file
        // Ensure measuredDurations index access is safe
        offsetInFile = validDurations[fileIndex] !== undefined ? validDurations[fileIndex] : 0;
      }

      if (fileIndex !== -1) {
        durationMap[part.start_time] = {
          fileIndex,
          offsetInFile
        };
      } else {
        // This case should ideally not happen if start_time is within totalDuration
        console.warn(`Could not map start_time ${part.start_time} to any audio file. Total Duration: ${totalDuration}`);
      }
    });

    console.log('Total audio duration:', totalDuration);
    console.log('Duration map:', durationMap);

    // Update state with the measured durations and the new map
    setRecordings(updatedRecordings);
    setTotalAudioDuration(totalDuration);
    setAudioDurationMap(durationMap);

    setAudioLoadingState('complete');
    // Ensure progress shows 100% at the end
    if (previousProgress < 100) {
      setAudioLoadingProgress(100);
    }
  };

  // Function to get audio duration using a specific audio element
  const getAudioDuration = (url: string, audio: HTMLAudioElement): Promise<number> => {
    return new Promise((resolve, reject) => {
      let resolved = false;
      let timer: NodeJS.Timeout | null = null;

      const cleanup = () => {
        if (timer) clearTimeout(timer);
        audio.removeEventListener('loadedmetadata', onLoadedMetadata);
        audio.removeEventListener('error', onError);
        // Temporary elements aren't added to DOM, so no need to removeChild
        // Just ensure references are released for GC
      };

      const onLoadedMetadata = () => {
        if (resolved) return;
        resolved = true;
        const duration = audio.duration;
        cleanup();
        // Check for invalid duration which can happen sometimes
        if (isNaN(duration) || !isFinite(duration)) {
          reject(new Error(`Invalid duration received for ${url}: ${duration}`));
        } else {
          resolve(duration);
        }
      };

      const onError = (e: Event) => {
        if (resolved) return;
        resolved = true;
        cleanup();
        // Try to get more specific error information if possible
        const error = audio.error;
        let errorMessage = `Failed to load audio: ${url}`;
        if (error) {
          errorMessage += ` (Code: ${error.code}, Message: ${error.message})`;
        }
        reject(new Error(errorMessage));
      };

      // Set a timeout to prevent hanging indefinitely
      timer = setTimeout(() => {
        if (!resolved) {
          resolved = true;
          cleanup();
          reject(new Error(`Timeout loading metadata for audio: ${url}`));
        }
      }, 15000); // 15 second timeout

      audio.addEventListener('loadedmetadata', onLoadedMetadata);
      audio.addEventListener('error', onError);
      audio.src = url;
      audio.preload = 'metadata'; // Important: only load metadata
      audio.load();
    });
  };

  // Cleanup effect for the main hidden audio ref if component unmounts during loading
  useEffect(() => {
    return () => {
      if (hiddenAudioRef.current && document.body.contains(hiddenAudioRef.current)) {
        hiddenAudioRef.current.remove();
        hiddenAudioRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch shift details and server name
        const shiftDetailsSql = `
          SELECT 
            server.name as server_name,
            shift.created as shift_created,
            CASE
              WHEN MAX(transcript_part.created) > shift.ended OR shift.ended IS NULL 
              THEN MAX(transcript_part.created)
              ELSE shift.ended
            END as shift_ended
          FROM shift
          JOIN server ON shift.server_id = server.id
          LEFT JOIN transcript_part ON shift.id = transcript_part.shift_id
          WHERE shift.id = "${shiftId}"
          GROUP BY shift.id, server.name, shift.created, shift.ended
        `;

        const shiftDetailsResponse = await fetch(
          `https://${RESTAURANT_BACKEND_HOST}/admin/execute-select?sql=${encodeURIComponent(shiftDetailsSql)}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!shiftDetailsResponse.ok) {
          throw new Error('Failed to fetch shift details');
        }

        const shiftDetailsData = await shiftDetailsResponse.json();

        // Fetch connection event data
        const connectionEventSql = `
          SELECT 
            event_data
          FROM shift_event
          WHERE shift_id = "${shiftId}" AND event_type = "client_connected"
          ORDER BY created ASC
          LIMIT 1
        `;

        const connectionEventResponse = await fetch(
          `https://${RESTAURANT_BACKEND_HOST}/admin/execute-select?sql=${encodeURIComponent(connectionEventSql)}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!connectionEventResponse.ok) {
          throw new Error('Failed to fetch connection event data');
        }

        const connectionEventData = await connectionEventResponse.json();
        const deviceInfo = connectionEventData.results.length > 0 ? connectionEventData.results[0].event_data : null;

        if (shiftDetailsData.results && shiftDetailsData.results.length > 0) {
          setShiftDetails({
            serverName: shiftDetailsData.results[0].server_name,
            shiftCreated: shiftDetailsData.results[0].shift_created,
            shiftEnded: shiftDetailsData.results[0].shift_ended,
            deviceInfo: deviceInfo
          });
        }

        // Fetch transcript parts
        const sql = `
          SELECT 
            id,
            speaker,
            start_time, 
            text,
            created 
          FROM transcript_part 
          WHERE shift_id = "${shiftId}"
          ORDER BY start_time ASC, sort_order ASC
        `;

        const transcriptResponse = await fetch(
          `https://${RESTAURANT_BACKEND_HOST}/admin/execute-select?sql=${encodeURIComponent(sql)}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!transcriptResponse.ok) {
          throw new Error('Failed to fetch transcript data');
        }

        const transcriptData = await transcriptResponse.json();
        setTranscriptParts(transcriptData.results);

        // Fetch shift events
        const eventsSql = `
          SELECT 
            id,
            event_type,
            event_data,
            created
          FROM shift_event 
          WHERE shift_id = "${shiftId}"
          ORDER BY created ASC
        `;

        const eventsResponse = await fetch(
          `https://${RESTAURANT_BACKEND_HOST}/admin/execute-select?sql=${encodeURIComponent(eventsSql)}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!eventsResponse.ok) {
          throw new Error('Failed to fetch shift events');
        }

        const eventsData = await eventsResponse.json();
        setShiftEvents(eventsData.results);

        // Fetch interactions
        const interactionsSql = `
          SELECT 
            id,
            start_time,
            end_time,
            interaction_type,
            data,
            created,
            correct_human_verified
          FROM shift_interaction 
          WHERE shift_id = "${shiftId}"
          ORDER BY start_time ASC
        `;

        const interactionsResponse = await fetch(
          `https://${RESTAURANT_BACKEND_HOST}/admin/execute-select?sql=${encodeURIComponent(interactionsSql)}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!interactionsResponse.ok) {
          throw new Error('Failed to fetch interactions');
        }

        const interactionsData = await interactionsResponse.json();
        setInteractions(interactionsData.results);

        // Fetch recordings
        if (serverId) {
          const recordingsResponse = await fetch(
            `https://${RESTAURANT_BACKEND_HOST}/admin/shift/${shiftId}/recordings?server_id=${serverId}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          if (!recordingsResponse.ok) {
            throw new Error('Failed to fetch recordings');
          }

          const recordingsData = await recordingsResponse.json();
          setRecordings(recordingsData.recordings);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An unknown error occurred');
        console.error('Error fetching data:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [shiftId, serverId]);

  // Effect to start loading audio files after initial data load
  useEffect(() => {
    if (!isLoading && recordings.length > 0 && audioLoadingState === 'idle') {
      loadAudioDurations();
    }
  }, [isLoading, recordings.length, audioLoadingState]);

  // Combine and sort transcript parts and shift events by timestamp
  useEffect(() => {
    // Create timeline items from transcript parts
    const transcriptItems: TimelineItem[] = transcriptParts.map(part => ({
      type: 'transcript',
      data: part,
      created: part.created
    }));

    // Create timeline items from shift events
    const eventItems: TimelineItem[] = shiftEvents.map(event => ({
      type: 'event',
      data: event,
      created: event.created
    }));

    // Combine and sort
    const combined = [...transcriptItems, ...eventItems].sort((a, b) => {
      return new Date(a.created).getTime() - new Date(b.created).getTime();
    });

    setTimelineItems(combined);
  }, [transcriptParts, shiftEvents]);

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleAudioEnded = () => {
    if (currentRecordingIndex < recordings.length - 1) {
      setCurrentRecordingIndex(currentRecordingIndex + 1);
      // The audio will automatically play the next recording due to the useEffect below
    } else {
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    if (audioRef.current && recordings.length > 0 && isPlaying) {
      audioRef.current.play();
    }
  }, [currentRecordingIndex, recordings.length]);

  const handlePlayFromTimestamp = (startTime: number) => {
    if (audioRef.current && recordings.length > 0 && audioLoadingState === 'complete') {
      // Get the correct file and position from our duration map
      const fileInfo = audioDurationMap[startTime];

      if (fileInfo) {
        // Set the current recording index to the correct file
        setCurrentRecordingIndex(fileInfo.fileIndex);

        // We need to wait for the audio src to update (in the next render cycle)
        setTimeout(() => {
          if (audioRef.current) {
            // Set the current time to the correct offset in the file
            audioRef.current.currentTime = fileInfo.offsetInFile;
            // Start playing
            audioRef.current.play();
            setIsPlaying(true);
          }
        }, 50);
      } else {
        console.warn(`No file info found for timestamp ${startTime}`);
      }
    }
  };

  const handleMarkInteraction = async (interactionId: string, status: boolean | null) => {
    try {
      const response = await fetch(
        `https://${RESTAURANT_BACKEND_HOST}/shift-interactions/${interactionId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ correct_human_verified: status })
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update interaction');
      }

      // Update the interaction in local state
      setInteractions(interactions.map(i =>
        i.id === interactionId
          ? { ...i, correct_human_verified: status }
          : i
      ));
    } catch (err) {
      console.error('Error updating interaction:', err);
      alert('Failed to update interaction');
    }
  };

  // Filter interactions based on verification status and type (copied from ShiftPerformanceView)
  const getFilteredInteractions = () => {
    return interactions.filter(interaction => {
      // Filter by verification status if needed
      if (showVerifiedOnly && interaction.correct_human_verified !== true) {
        return false;
      }

      // Check if the interaction has service_criteria data
      return interaction.data && interaction.data.service_criteria;
    });
  };

  // Calculate statistics for each criterion (copied from ShiftPerformanceView)
  const calculateCriteriaStats = (): CriteriaStats[] => {
    const filteredInteractions = getFilteredInteractions();

    return criteriaTypes.map(criterionType => {
      let fulfilled = 0;
      let total = 0;

      filteredInteractions.forEach(interaction => {
        const serviceCriteria = interaction.data.service_criteria as ServiceCriterion[];
        if (!serviceCriteria) return;

        const criterion = serviceCriteria.find(c => c.type === criterionType);
        if (criterion) {
          total++;
          if (criterion.fulfilled) {
            fulfilled++;
          }
        }
      });

      const percentage = total > 0 ? (fulfilled / total) * 100 : 0;

      return {
        criterionType,
        fulfilled,
        total,
        percentage
      };
    });
  };

  // Format date for display
  const formatDate = (dateString: string | null) => {
    if (!dateString) return 'N/A';

    // Ensure the date string is treated as UTC by appending 'Z' if not present
    const utcDateString = dateString.endsWith('Z') ? dateString : `${dateString}Z`;

    // Create date object from UTC string (the Z suffix tells JS this is UTC)
    const date = new Date(utcDateString);

    // Format to "Month DD, HH:MM am/pm"
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    };

    return date.toLocaleDateString('en-US', options);
  };

  // Calculate duration in hours and minutes
  const calculateDuration = (startDateString: string | null, endDateString: string | null) => {
    if (!startDateString || !endDateString) return 'N/A';

    // Ensure the date strings are treated as UTC
    const startUtcString = startDateString.endsWith('Z') ? startDateString : `${startDateString}Z`;
    const endUtcString = endDateString.endsWith('Z') ? endDateString : `${endDateString}Z`;

    // Create date objects
    const startDate = new Date(startUtcString);
    const endDate = new Date(endUtcString);

    // Calculate difference in milliseconds
    const diffMs = endDate.getTime() - startDate.getTime();

    // Convert to hours and minutes
    const hours = Math.floor(diffMs / (1000 * 60 * 60));
    const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours}h ${minutes}m`;
  };

  // Format shift event data for display
  const formatEventData = (data: any): string => {
    try {
      if (typeof data === 'string') {
        // Try to parse if it's a JSON string
        return JSON.stringify(JSON.parse(data), null, 2);
      }
      return JSON.stringify(data, null, 2);
    } catch (e) {
      return typeof data === 'string' ? data : JSON.stringify(data);
    }
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }

  if (error) {
    return <div className="text-red-500 p-4">Error: {error}</div>;
  }

  if (transcriptParts.length === 0) {
    return <div className="p-4">No transcript data available for this shift.</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl" ref={transcriptRef}>
      <div className="flex justify-between items-center mb-2">
        <h1 className="text-2xl font-bold">Transcript for Shift #{shiftId}</h1>
      </div>

      {shiftDetails && (
        <div className="bg-white shadow-md rounded-lg p-4 mb-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <h3 className="text-sm font-semibold text-gray-500">Server</h3>
              <p className="text-lg font-medium">{shiftDetails.serverName}</p>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-gray-500">Started</h3>
              <p className="text-lg">{formatDate(shiftDetails.shiftCreated)}</p>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-gray-500">Duration</h3>
              <p className="text-lg">{calculateDuration(shiftDetails.shiftCreated, shiftDetails.shiftEnded)}</p>
            </div>
          </div>

          {shiftDetails.deviceInfo && (
            <div className="mt-4 border-t pt-4">
              <h3 className="text-sm font-semibold text-gray-500 mb-2">Client Information</h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <p className="text-xs font-semibold text-gray-500">Client Type</p>
                  <p className="text-sm">{shiftDetails.deviceInfo.client_type}</p>
                </div>
                {shiftDetails.deviceInfo.device_name && (
                  <div>
                    <p className="text-xs font-semibold text-gray-500">Device Name</p>
                    <p className="text-sm">{shiftDetails.deviceInfo.device_name}</p>
                  </div>
                )}
                {shiftDetails.deviceInfo.device_model && (
                  <div>
                    <p className="text-xs font-semibold text-gray-500">Device Model</p>
                    <p className="text-sm">{shiftDetails.deviceInfo.device_model}</p>
                  </div>
                )}
                {shiftDetails.deviceInfo.system_name && (
                  <div>
                    <p className="text-xs font-semibold text-gray-500">OS</p>
                    <p className="text-sm">{shiftDetails.deviceInfo.system_name} {shiftDetails.deviceInfo.system_version}</p>
                  </div>
                )}
                {shiftDetails.deviceInfo.app_version && (
                  <div>
                    <p className="text-xs font-semibold text-gray-500">App Version</p>
                    <p className="text-sm">{shiftDetails.deviceInfo.app_version}</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      {/* Add performance summary section */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Performance Summary</h2>
          <div className="flex items-center">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-600 mr-2"
              checked={showVerifiedOnly}
              onChange={() => setShowVerifiedOnly(!showVerifiedOnly)}
              id="verified-checkbox"
            />
            <label htmlFor="verified-checkbox" className="text-gray-700 text-sm">
              Show only human-verified interactions
            </label>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          {calculateCriteriaStats().map(stat => (
            <div key={stat.criterionType} className="flex flex-col">
              <div className="flex justify-between items-center mb-1">
                <span className="text-sm font-medium">{criteriaLabels[stat.criterionType]}</span>
                <span className="text-sm font-medium">{stat.fulfilled}/{stat.total} ({stat.percentage.toFixed(1)}%)</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-blue-600 h-2.5 rounded-full"
                  style={{ width: `${stat.percentage}%` }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {interactions.length > 0 && (
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">Interactions</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto">
              <thead>
                <tr className="bg-gray-50">
                  <th className="px-4 py-2 text-left">Time</th>
                  <th className="px-4 py-2 text-left">Duration</th>
                  <th className="px-4 py-2 text-left">Type</th>
                  <th className="px-4 py-2 text-left">Status</th>
                  <th className="px-4 py-2 text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                {interactions.map((interaction) => (
                  <tr key={interaction.id} className="border-t">
                    <td className="px-4 py-2">
                      {formatTimestamp(interaction.start_time)} - {formatTimestamp(interaction.end_time)}
                    </td>
                    <td className="px-4 py-2">
                      {(interaction.end_time - interaction.start_time).toFixed(1)}s
                    </td>
                    <td className="px-4 py-2 capitalize">{interaction.interaction_type}</td>
                    <td className="px-4 py-2">
                      {interaction.correct_human_verified !== null && (
                        <span className={`text-xs font-medium ${interaction.correct_human_verified
                          ? 'text-green-600'
                          : 'text-red-600'
                          }`}>
                          {interaction.correct_human_verified
                            ? 'Verified Correct'
                            : 'Verified Incorrect'}
                        </span>
                      )}
                      {interaction.correct_human_verified === null && (
                        <span className="text-xs text-gray-500">
                          Not Verified
                        </span>
                      )}
                    </td>
                    <td className="px-4 py-2">
                      <button
                        onClick={() => {
                          const element = document.querySelector(`[data-time="${interaction.start_time}"]`);
                          if (element) {
                            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                          }
                          if (audioRef.current) {
                            audioRef.current.currentTime = interaction.start_time;
                          }
                        }}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        Jump to section
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {recordings.length > 0 && (
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">Audio Recordings</h2>

          {audioLoadingState === 'loading' ? (
            <div className="flex flex-col items-center">
              <h3 className="text-lg mb-2">Loading audio files... {audioLoadingProgress}%</h3>
              <div className="w-full bg-gray-200 rounded-full h-4">
                <div
                  className="bg-blue-600 h-4 rounded-full"
                  style={{ width: `${audioLoadingProgress}%` }}
                ></div>
              </div>
              <p className="text-sm text-gray-500 mt-2">Please wait while we prepare the audio playback...</p>
            </div>
          ) : (
            <div className="flex flex-col items-center">
              <audio
                ref={audioRef}
                src={recordings[currentRecordingIndex]?.url}
                onEnded={handleAudioEnded}
                controls
                className="w-full mb-4"
              />
              <div className="flex items-center space-x-4">
                <button
                  onClick={handlePlayPause}
                  className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
                  disabled={audioLoadingState !== 'complete'}
                >
                  {isPlaying ? 'Pause' : 'Play All'}
                </button>
                <span className="text-gray-600">
                  Recording {currentRecordingIndex + 1} of {recordings.length}
                </span>
              </div>
              {audioLoadingState === 'complete' && (
                <div className="text-sm text-gray-500 mt-2">
                  Total duration: {Math.floor(totalAudioDuration / 60)}m {Math.round(totalAudioDuration % 60)}s
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <div className="bg-white shadow-md rounded-lg p-6">
        {timelineItems.map((item, index) => {
          if (item.type === 'transcript') {
            const part = item.data as TranscriptPart;
            const hasInteractions = isTimeInAnyInteraction(part.start_time);
            const matchingInteractions = getInteractionsForTime(part.start_time);

            // Only show buttons for interactions where this is the first transcript part
            const interactionsToShowButtons = matchingInteractions.filter(
              interaction => getFirstTranscriptPartForInteraction(interaction) === part.id
            );

            return (
              <div key={`transcript-${part.id}`} className="mb-2">
                {interactionsToShowButtons.length > 0 && (
                  <div className="flex flex-col space-y-1 mb-2">
                    {interactionsToShowButtons.map(interaction => (
                      <div key={interaction.id} className="flex items-center space-x-2">
                        <div className="flex space-x-1">
                          {interaction.correct_human_verified === null && (
                            <>
                              <button
                                onClick={() => handleMarkInteraction(interaction.id, true)}
                                className="px-2 py-1 text-xs bg-green-100 text-green-800 rounded hover:bg-green-200"
                                title="Mark as correct"
                              >
                                Correct
                              </button>
                              <button
                                onClick={() => handleMarkInteraction(interaction.id, false)}
                                className="px-2 py-1 text-xs bg-red-100 text-red-800 rounded hover:bg-red-200"
                                title="Mark as incorrect"
                              >
                                Incorrect
                              </button>
                            </>
                          )}
                          {interaction.correct_human_verified !== null && (
                            <div className="flex items-center space-x-2">
                              <span className={`text-xs font-medium ${interaction.correct_human_verified
                                ? 'text-green-600'
                                : 'text-red-600'
                                }`}>
                                {interaction.correct_human_verified
                                  ? 'Verified Correct'
                                  : 'Verified Incorrect'}
                              </span>
                              <button
                                onClick={() => handleMarkInteraction(interaction.id, null)}
                                className="px-2 py-1 text-xs bg-gray-100 text-gray-800 rounded hover:bg-gray-200"
                                title="Remove marking"
                              >
                                Clear
                              </button>
                            </div>
                          )}
                        </div>
                        <span className="text-sm text-gray-600 font-mono">
                          {buildFeedbackDisplay(interaction.data)}
                        </span>
                        <button
                          onClick={() => handleNextInteraction(interaction.start_time)}
                          className="px-2 py-1 text-xs bg-blue-100 text-blue-800 rounded hover:bg-blue-200"
                          title="Go to next interaction"
                        >
                          Next →
                        </button>
                      </div>
                    ))}
                  </div>
                )}
                <div className="flex items-start">
                  {recordings.length > 0 && (
                    <div className="flex mr-2 mt-1 space-x-1">
                      <button
                        onClick={() => handlePlayFromTimestamp(part.start_time)}
                        className={`${audioLoadingState === 'complete' ? 'text-blue-500 hover:text-blue-700' : 'text-gray-300'} flex-shrink-0 p-1`}
                        disabled={audioLoadingState !== 'complete'}
                        title={audioLoadingState === 'complete'
                          ? `Play from ${formatTimestamp(part.start_time)}`
                          : 'Loading audio files...'
                        }
                      >
                        <FaPlay className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => {
                          if (audioRef.current && isPlaying) {
                            audioRef.current.pause();
                            setIsPlaying(false);
                          }
                        }}
                        className={`${isPlaying && audioLoadingState === 'complete' ? 'text-red-500 hover:text-red-700' : 'text-gray-300'} flex-shrink-0 p-1`}
                        disabled={!isPlaying || audioLoadingState !== 'complete'}
                        title="Pause playback"
                      >
                        <FaPause className="h-5 w-5" />
                      </button>
                    </div>
                  )}
                  <div>
                    <span className="text-xs text-gray-500 mr-2">
                      [{formatTimestamp(part.start_time)}]
                    </span>
                    <span
                      data-time={part.start_time}
                      className={`relative group ${hasInteractions ? 'bg-yellow-50 rounded px-1' : ''}`}
                    >
                      [{part.speaker}]: {part.text}
                    </span>
                    {hasInteractions && (
                      <div className="hidden group-hover:block absolute bg-white p-4 rounded shadow-lg z-10 max-w-4xl border border-gray-200 mt-2">
                        {matchingInteractions.map(interaction => (
                          <div key={interaction.id} className="text-sm text-gray-600 mb-2">
                            <div className="flex justify-between items-start mb-1">
                              <span className="font-semibold capitalize">{interaction.interaction_type}</span>
                            </div>
                            <pre className="mt-1 text-xs whitespace-pre-wrap bg-gray-50 p-2 rounded">
                              {JSON.stringify(interaction.data, null, 2)}
                            </pre>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          } else {
            // Render shift event
            const event = item.data as ShiftEvent;

            return (
              <div key={`event-${event.id}`} className="mb-4 border-l-4 border-blue-400 pl-4 py-2 bg-blue-50 rounded">
                <div className="flex items-center mb-1">
                  <FaCalendarAlt className="text-blue-500 mr-2" />
                  <span className="font-medium text-blue-700">Event: {event.event_type}</span>
                </div>
                <div className="text-xs text-gray-500 mb-2">
                  {formatDate(event.created)}
                </div>
                <pre className="text-xs bg-white p-2 rounded border border-gray-200 overflow-auto max-h-60">
                  {formatEventData(event.event_data)}
                </pre>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default ShiftTranscriptView;
