import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import FullPageSpinner from '../../FullPageSpinner';
import { RESTAURANT_BACKEND_HOST } from '../RestaurantBackend';

interface Location {
  id: string;
  name: string;
}

interface ServerShift {
  shift_id: string;
  shift_created: string;
  shift_ended: string | null;
  duration_minutes: number;
  interactions_count: number;
  fragments_count: number;
}

interface ServerStats {
  server_id: string;
  server_name: string;
  location_id: string;
  location_name: string;
  total_shifts: number;
  total_interactions: number;
  total_fragments: number;
  total_duration_minutes: number;
  criteria_stats: {
    upsell_count: number;
    mention_count: number;
    describe_count: number;
  };
  shifts: ServerShift[];
}

const TrialServerDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string>('');
  const [serverStats, setServerStats] = useState<ServerStats[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const START_DATE = '2025-03-28'; // Updated from March 29 to March 28 as requested

  // Get query parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isAdvanced = queryParams.get('advanced') === 'true';

  // Fixed location IDs from the SQL data
  const FREMONT_LOCATION_ID = '22222222-2222-2222-2222-222222222222';
  const CONCORD_LOCATION_ID = '33333333-3333-3333-3333-333333333333';

  // Fetch locations
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const sql = `
          SELECT id, name 
          FROM location 
          ORDER BY name ASC
        `;

        const response = await fetch(
          `https://${RESTAURANT_BACKEND_HOST}/admin/execute-select?sql=${encodeURIComponent(sql)}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch locations');
        }

        const data = await response.json();
        setLocations(data.results);

        // Set the first location as default if we don't have a selection yet
        if (data.results.length > 0 && !selectedLocation) {
          setSelectedLocation(data.results[0].id);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An unknown error occurred');
        console.error('Error fetching locations:', err);
      }
    };

    fetchLocations();
  }, []);

  // Fetch server data based on location and after March 29
  useEffect(() => {
    const fetchTrialServerData = async () => {
      setIsLoading(true);
      try {
        // Get all servers with trial_focus=true from both Concord and Fremont
        const serversQuery = `
          SELECT s.id as server_id, s.name as server_name, l.id as location_id, l.name as location_name
          FROM server s
          JOIN location l ON s.location_id = l.id
          WHERE l.id IN ('${FREMONT_LOCATION_ID}', '${CONCORD_LOCATION_ID}')
          AND JSON_EXTRACT(s.features, '$.trial_focus') = true
          ORDER BY s.name ASC
        `;

        const serversResponse = await fetch(
          `https://${RESTAURANT_BACKEND_HOST}/admin/execute-select?sql=${encodeURIComponent(serversQuery)}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!serversResponse.ok) {
          throw new Error('Failed to fetch trial servers data');
        }

        const serversData = await serversResponse.json();
        const servers = serversData.results;

        if (servers.length === 0) {
          setServerStats([]);
          setIsLoading(false);
          return;
        }

        // Get all server IDs to use in the shifts query
        const serverIds = servers.map((server: any) => `'${server.server_id}'`).join(',');

        // Get all shifts for these servers since March 29
        const shiftsQuery = `
          SELECT 
            s.id as shift_id,
            s.server_id,
            s.created as shift_created,
            CASE
              WHEN MAX(tp.created) > s.ended OR s.ended IS NULL 
              THEN MAX(tp.created)
              ELSE s.ended
            END as shift_ended,
            COUNT(DISTINCT si.id) as interactions_count,
            COUNT(tp.id) as fragments_count
          FROM shift s
          LEFT JOIN transcript_part tp ON tp.shift_id = s.id
          LEFT JOIN shift_interaction si ON si.shift_id = s.id
          WHERE s.server_id IN (${serverIds})
            AND DATE(CONVERT_TZ(s.created, 'UTC', 'US/Pacific')) >= '${START_DATE}'
          GROUP BY s.id, s.server_id, s.created, s.ended
          ORDER BY s.created DESC
        `;

        const shiftsResponse = await fetch(
          `https://${RESTAURANT_BACKEND_HOST}/admin/execute-select?sql=${encodeURIComponent(shiftsQuery)}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!shiftsResponse.ok) {
          throw new Error('Failed to fetch shifts data');
        }

        const shiftsData = await shiftsResponse.json();
        const shifts = shiftsData.results;

        // Get all shift IDs to use in the service criteria query
        const shiftIds = shifts.map((shift: any) => `'${shift.shift_id}'`).join(',');

        if (shiftIds.length === 0) {
          // Process server data without shifts
          const statsWithNoShifts = servers.map((server: any) => ({
            server_id: server.server_id,
            server_name: server.server_name,
            location_id: server.location_id,
            location_name: server.location_name,
            total_shifts: 0,
            total_interactions: 0,
            total_fragments: 0,
            total_duration_minutes: 0,
            criteria_stats: {
              upsell_count: 0,
              mention_count: 0,
              describe_count: 0
            },
            shifts: []
          }));

          setServerStats(statsWithNoShifts);
          setIsLoading(false);
          return;
        }

        // Get service criteria stats from all interactions
        const criteriaQuery = `
          SELECT 
            si.shift_id,
            si.id as interaction_id,
            si.data as interaction_data
          FROM shift_interaction si
          WHERE si.shift_id IN (${shiftIds})
        `;

        const criteriaResponse = await fetch(
          `https://${RESTAURANT_BACKEND_HOST}/admin/execute-select?sql=${encodeURIComponent(criteriaQuery)}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!criteriaResponse.ok) {
          throw new Error('Failed to fetch service criteria data');
        }

        const criteriaData = await criteriaResponse.json();
        const criteriaResults = criteriaData.results;

        // Debug: Log sample data to understand the structure
        if (criteriaResults.length > 0) {
          console.log('Sample interaction data structure:', {
            first_item: criteriaResults[0],
            interaction_data_type: typeof criteriaResults[0].interaction_data,
            sample: criteriaResults[0].interaction_data
          });
        }

        // Group shifts by server_id
        const shiftsByServer = shifts.reduce((acc: { [key: string]: any[] }, shift: any) => {
          if (!acc[shift.server_id]) {
            acc[shift.server_id] = [];
          }

          // Calculate shift duration
          let durationMinutes = 0;
          if (shift.shift_created && shift.shift_ended) {
            const startTime = new Date(shift.shift_created.endsWith('Z')
              ? shift.shift_created
              : `${shift.shift_created}Z`);

            const endTime = new Date(shift.shift_ended.endsWith('Z')
              ? shift.shift_ended
              : `${shift.shift_ended}Z`);

            durationMinutes = Math.round((endTime.getTime() - startTime.getTime()) / 60000);
          }

          acc[shift.server_id].push({
            ...shift,
            duration_minutes: durationMinutes
          });

          return acc;
        }, {});

        // Group criteria by shift_id
        const criteriaByShift = criteriaResults.reduce((acc: { [key: string]: any[] }, item: any) => {
          if (!acc[item.shift_id]) {
            acc[item.shift_id] = [];
          }
          acc[item.shift_id].push(item);
          return acc;
        }, {});

        // Process all data to create aggregated server stats
        const stats = servers.map((server: any) => {
          const serverShifts = shiftsByServer[server.server_id] || [];

          // Initialize counters
          let totalInteractions = 0;
          let totalFragments = 0;
          let totalDurationMinutes = 0;
          let upsellCount = 0;
          let mentionCount = 0;
          let describeCount = 0;

          // Process each shift for this server
          const processedShifts = serverShifts.map((shift: any) => {
            totalInteractions += Number(shift.interactions_count) || 0;
            totalFragments += Number(shift.fragments_count) || 0;
            totalDurationMinutes += shift.duration_minutes || 0;

            // Get interactions for this shift
            const shiftCriteria = criteriaByShift[shift.shift_id] || [];

            // Process criteria for each interaction in this shift
            shiftCriteria.forEach((criteriaItem: any) => {
              try {
                // The interaction_data might be a string or an object
                let data: any;

                if (typeof criteriaItem.interaction_data === 'string') {
                  data = JSON.parse(criteriaItem.interaction_data);
                } else {
                  data = criteriaItem.interaction_data;
                }

                // Debug output to understand the structure
                if (shiftCriteria.indexOf(criteriaItem) === 0) {
                  console.log(`Sample processed interaction data for shift ${shift.shift_id}:`, {
                    data_type: typeof data,
                    data_sample: data,
                    has_service_criteria: !!data?.service_criteria,
                    service_criteria_type: data?.service_criteria ? typeof data.service_criteria : 'N/A'
                  });
                }

                // Check if we have service_criteria
                if (data && data.service_criteria) {
                  let criteriaArray = data.service_criteria;

                  // Make sure criteriaArray is an array
                  if (typeof criteriaArray === 'string') {
                    criteriaArray = JSON.parse(criteriaArray);
                  }

                  if (Array.isArray(criteriaArray)) {
                    // Find each criterion by type and check if it's fulfilled
                    const upsellCriterion = criteriaArray.find((c: any) => c.type === 'upsell_premium_menu');
                    if (upsellCriterion && upsellCriterion.fulfilled === true) {
                      upsellCount++;
                    }

                    const mentionCriterion = criteriaArray.find((c: any) => c.type === 'mention_specific_premium_item');
                    if (mentionCriterion && mentionCriterion.fulfilled === true) {
                      mentionCount++;
                    }

                    const describeCriterion = criteriaArray.find((c: any) => c.type === 'describe_specific_premium_item');
                    if (describeCriterion && describeCriterion.fulfilled === true) {
                      describeCount++;
                    }
                  }
                }
              } catch (e) {
                console.error('Error processing interaction data:', e);
              }
            });

            return {
              shift_id: shift.shift_id,
              shift_created: shift.shift_created,
              shift_ended: shift.shift_ended,
              duration_minutes: shift.duration_minutes,
              interactions_count: Number(shift.interactions_count) || 0,
              fragments_count: Number(shift.fragments_count) || 0
            };
          });

          return {
            server_id: server.server_id,
            server_name: server.server_name,
            location_id: server.location_id,
            location_name: server.location_name,
            total_shifts: serverShifts.length,
            total_interactions: totalInteractions,
            total_fragments: totalFragments,
            total_duration_minutes: totalDurationMinutes,
            criteria_stats: {
              upsell_count: upsellCount,
              mention_count: mentionCount,
              describe_count: describeCount
            },
            shifts: processedShifts
          };
        });

        setServerStats(stats);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An unknown error occurred');
        console.error('Error fetching server data:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTrialServerData();
  }, []);

  // Format date for compact display (MMM d, h:mm a)
  const formatCompactDate = (dateString: string | null) => {
    if (!dateString) return 'N/A';

    // Ensure the date string is treated as UTC
    const utcDateString = dateString.endsWith('Z') ? dateString : `${dateString}Z`;
    const date = new Date(utcDateString);

    return format(date, 'MMM d, h:mm a'); // No year, 3 letter month, day, and time
  };

  // Check if a date is on or after the start date
  const isDateOnOrAfterStartDate = (dateString: string | null): boolean => {
    if (!dateString) return false;

    // Parse the start date
    const startDate = new Date(START_DATE);
    startDate.setHours(0, 0, 0, 0); // Set to beginning of day

    // Parse the shift date (ensure UTC handling)
    const utcDateString = dateString.endsWith('Z') ? dateString : `${dateString}Z`;
    const shiftDate = new Date(utcDateString);

    // Convert to PST/PDT for comparison
    const shiftDatePST = new Date(shiftDate.getTime() - (7 * 60 * 60 * 1000)); // Rough PST adjustment
    const shiftDatePSTDay = new Date(
      shiftDatePST.getFullYear(),
      shiftDatePST.getMonth(),
      shiftDatePST.getDate()
    );

    return shiftDatePSTDay >= startDate;
  };

  // Format duration in hours and minutes
  const formatDuration = (minutes: number): string => {
    if (minutes <= 0) return 'N/A';

    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    return `${hours > 0 ? `${hours}h ` : ''}${mins}m`;
  };

  // Format percentage
  const formatPercentage = (value: number, total: number) => {
    if (total === 0) return '0%';
    // Ensure we're using the correct calculation
    const percentage = Math.round((value / total) * 100);
    return `${percentage}%`;
  };

  // Calculate average score from the three metrics
  const calculateScore = (stats: {
    upsell_count: number;
    mention_count: number;
    describe_count: number;
  }, totalInteractions: number): number => {
    if (totalInteractions === 0) return 0;

    const upsellPercentage = (stats.upsell_count / totalInteractions) * 100;
    const mentionPercentage = (stats.mention_count / totalInteractions) * 100;
    const describePercentage = (stats.describe_count / totalInteractions) * 100;

    return Math.round((upsellPercentage + mentionPercentage + describePercentage) / 3);
  };

  // Calculate location average score
  const calculateLocationAverage = (locationId: string): number => {
    const locationServers = serverStats.filter(server => server.location_id === locationId);

    if (locationServers.length === 0) return 0;

    const totalScore = locationServers.reduce((sum, server) => {
      return sum + calculateScore(server.criteria_stats, server.total_interactions);
    }, 0);

    return Math.round(totalScore / locationServers.length);
  };

  // Get servers for a specific location
  const getServersForLocation = (locationId: string): ServerStats[] => {
    return [...serverStats]
      .filter(server => server.location_id === locationId)
      .map(server => {
        // Filter shifts to only include those on or after START_DATE
        const filteredShifts = server.shifts.filter(shift =>
          isDateOnOrAfterStartDate(shift.shift_created)
        );

        // Recalculate totals based on filtered shifts
        let filteredTotalInteractions = 0;
        let filteredUpsellCount = 0;
        let filteredMentionCount = 0;
        let filteredDescribeCount = 0;

        // Only recalculate if we have filtered out shifts
        if (filteredShifts.length !== server.shifts.length) {
          filteredTotalInteractions = filteredShifts.reduce(
            (sum, shift) => sum + shift.interactions_count, 0
          );

          // We need to recalculate criteria stats for the filtered shifts
          // This is an approximation since we don't have the original interactions
          const originalRatio = server.shifts.length > 0 ? filteredShifts.length / server.shifts.length : 0;

          if (originalRatio > 0) {
            filteredUpsellCount = Math.round(server.criteria_stats.upsell_count * originalRatio);
            filteredMentionCount = Math.round(server.criteria_stats.mention_count * originalRatio);
            filteredDescribeCount = Math.round(server.criteria_stats.describe_count * originalRatio);
          }

          // Return modified server stats with filtered data
          return {
            ...server,
            shifts: filteredShifts,
            total_shifts: filteredShifts.length,
            total_interactions: filteredTotalInteractions,
            criteria_stats: {
              upsell_count: filteredUpsellCount,
              mention_count: filteredMentionCount,
              describe_count: filteredDescribeCount
            }
          };
        }

        // Return unmodified stats if no shifts were filtered out
        return server;
      })
      .sort((a, b) => {
        const scoreA = calculateScore(a.criteria_stats, a.total_interactions);
        const scoreB = calculateScore(b.criteria_stats, b.total_interactions);
        return scoreB - scoreA;
      });
  };

  const fremontServers = getServersForLocation(FREMONT_LOCATION_ID);
  const concordServers = getServersForLocation(CONCORD_LOCATION_ID);

  const fremontAverageScore = calculateLocationAverage(FREMONT_LOCATION_ID);
  const concordAverageScore = calculateLocationAverage(CONCORD_LOCATION_ID);

  if (isLoading && !serverStats.length) {
    return <FullPageSpinner />;
  }

  if (error && !serverStats.length) {
    return <div className="text-red-500 p-4">Error: {error}</div>;
  }

  // Table rendering function to avoid code duplication
  const renderServerTable = (servers: ServerStats[], locationName: string, averageScore: number, isAdvancedView: boolean) => (
    <div className="bg-white shadow-md rounded-lg p-4 md:p-6 mb-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold text-gray-800">{locationName}</h2>
        <div className="flex items-center">
          <span className="text-sm font-medium mr-2">Location Average:</span>
          <span className={`text-lg font-bold ${averageScore >= 70 ? 'text-green-600' :
            averageScore >= 40 ? 'text-yellow-600' : 'text-red-600'
            }`}>
            {averageScore}%
          </span>
        </div>
      </div>

      {servers.length === 0 ? (
        <div className="text-center py-8">
          <p className="text-gray-500">No trial servers found for {locationName}.</p>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[40%]">
                  Server
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[15%]">
                  Interactions
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[35%]">
                  Key Metrics
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[10%]">
                  Score
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {servers.map((serverStat) => {
                const score = calculateScore(serverStat.criteria_stats, serverStat.total_interactions);

                return (
                  <tr key={serverStat.server_id}>
                    <td className="px-6 py-4">
                      <div className="text-md font-medium text-gray-900">{serverStat.server_name}</div>

                      {/* Compact transcript links */}
                      <div className="flex flex-wrap gap-2 mt-2">
                        {serverStat.shifts.map((shift) => (
                          isAdvancedView ? (
                            <Link
                              key={shift.shift_id}
                              to={`/restaurant/shifts/${shift.shift_id}/transcript/${serverStat.server_id}`}
                              className="text-xs bg-blue-100 hover:bg-blue-200 text-blue-800 py-1 px-2 rounded inline-block"
                              title={`View transcript for shift started ${formatCompactDate(shift.shift_created)}`}
                            >
                              {formatCompactDate(shift.shift_created)}
                            </Link>
                          ) : (
                            <span
                              key={shift.shift_id}
                              className="text-xs bg-gray-100 text-gray-800 py-1 px-2 rounded inline-block"
                              title={`Shift started ${formatCompactDate(shift.shift_created)}`}
                            >
                              {formatCompactDate(shift.shift_created)}
                            </span>
                          )
                        ))}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-md font-medium text-gray-900">
                        {serverStat.total_interactions} ({serverStat.total_shifts} shifts)
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="space-y-3">
                        {/* Premium Menu - Mini progress bar */}
                        <div>
                          <div className="flex justify-between items-center mb-1">
                            <span className="text-xs font-medium">Premium Menu</span>
                            <span className="text-xs font-medium">{formatPercentage(serverStat.criteria_stats.upsell_count, serverStat.total_interactions)}</span>
                          </div>
                          <div className="w-full bg-gray-200 rounded-full h-1.5">
                            <div
                              className="bg-blue-600 h-1.5 rounded-full"
                              style={{ width: `${serverStat.total_interactions > 0 ? (serverStat.criteria_stats.upsell_count / serverStat.total_interactions * 100) : 0}%` }}
                            ></div>
                          </div>
                        </div>

                        {/* Item Mention - Mini progress bar */}
                        <div>
                          <div className="flex justify-between items-center mb-1">
                            <span className="text-xs font-medium">Item Mention</span>
                            <span className="text-xs font-medium">{formatPercentage(serverStat.criteria_stats.mention_count, serverStat.total_interactions)}</span>
                          </div>
                          <div className="w-full bg-gray-200 rounded-full h-1.5">
                            <div
                              className="bg-green-500 h-1.5 rounded-full"
                              style={{ width: `${serverStat.total_interactions > 0 ? (serverStat.criteria_stats.mention_count / serverStat.total_interactions * 100) : 0}%` }}
                            ></div>
                          </div>
                        </div>

                        {/* Item Description - Mini progress bar */}
                        <div>
                          <div className="flex justify-between items-center mb-1">
                            <span className="text-xs font-medium">Item Description</span>
                            <span className="text-xs font-medium">{formatPercentage(serverStat.criteria_stats.describe_count, serverStat.total_interactions)}</span>
                          </div>
                          <div className="w-full bg-gray-200 rounded-full h-1.5">
                            <div
                              className="bg-purple-500 h-1.5 rounded-full"
                              style={{ width: `${serverStat.total_interactions > 0 ? (serverStat.criteria_stats.describe_count / serverStat.total_interactions * 100) : 0}%` }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right">
                      <div className={`text-lg font-bold ${score >= 70 ? 'text-green-600' :
                        score >= 40 ? 'text-yellow-600' : 'text-red-600'
                        }`}>
                        {score}%
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-6 max-w-7xl">
      <h1 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6">
        Trial Server Performance Dashboard
      </h1>
      <div className="text-sm text-gray-500 mb-6">
        Showing data since {START_DATE}
      </div>

      {renderServerTable(fremontServers, 'Fremont', fremontAverageScore, isAdvanced)}
      {renderServerTable(concordServers, 'Concord', concordAverageScore, isAdvanced)}
    </div>
  );
};

export default TrialServerDashboard; 