import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useNavigate, useLocation } from 'react-router-dom';
import FullPageSpinner from '../../FullPageSpinner';
import { RESTAURANT_BACKEND_HOST } from '../RestaurantBackend';
import ReactMarkdown from 'react-markdown';

interface ServerSummary {
  serverId: string;
  serverName: string;
  shiftId: string;
  shiftStarted: string;
  shiftEnded: string | null;
  shiftDuration: number; // in minutes
  totalInteractions: number;
  fragments: number; // Add fragments count
  summaryText: string | null; // Add summary text
  summaryJson: {
    good_interaction?: {
      text: string;
      reasoning: string;
      interaction: {
        id: string;
        service_criteria: Array<{
          type: string;
          fulfilled: boolean;
        }>;
      };
    };
    bad_interaction?: {
      text: string;
      reasoning: string;
      interaction: {
        id: string;
        service_criteria: Array<{
          type: string;
          fulfilled: boolean;
        }>;
      };
    };
  } | null;
  criteriaStats: {
    upsellPremiumMenu: number;
    mentionSpecificPremiumItem: number;
    describeSpecificPremiumItem: number;
  }
}

interface Location {
  id: string;
  name: string;
}

interface ShiftData {
  shift_id: string;
  server_id: string;
  server_name: string;
  shift_created: string;
  shift_ended: string | null;
  summary_text: string | null;
  summary_json: string | null;
  interaction_id?: string;
  interaction_data?: string;
  fragments?: number;
}

const ServerSummaryView: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Get parameters from URL
  const urlDate = searchParams.get('date');
  const urlLocationId = searchParams.get('locationId');
  const isAdvancedMode = searchParams.get('advanced') === 'true';

  // Hard code minimum fragments to 100
  const MIN_FRAGMENTS = 100;

  const [selectedDate, setSelectedDate] = useState<string>(
    urlDate || format(new Date(), 'yyyy-MM-dd')
  );
  const [selectedLocation, setSelectedLocation] = useState<string>(urlLocationId || '');
  const [locations, setLocations] = useState<Location[]>([]);
  const [serverSummaries, setServerSummaries] = useState<ServerSummary[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // State for modals
  const [selectedInteraction, setSelectedInteraction] = useState<{
    text: string;
    reasoning: string;
    service_criteria: Array<{ type: string; fulfilled: boolean }>;
  } | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Add expanded state for mobile card view
  const [expandedCards, setExpandedCards] = useState<Set<string>>(new Set());

  // Toggle card expansion state
  const toggleCardExpansion = (shiftId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    setExpandedCards(prev => {
      const newSet = new Set(prev);
      if (newSet.has(shiftId)) {
        newSet.delete(shiftId);
      } else {
        newSet.add(shiftId);
      }
      return newSet;
    });
  };

  // Update URL when filters change
  useEffect(() => {
    if (selectedDate && selectedLocation) {
      const params = new URLSearchParams();
      params.set('date', selectedDate);
      params.set('locationId', selectedLocation);

      // Preserve the advanced parameter if it exists
      if (isAdvancedMode) {
        params.set('advanced', 'true');
      }

      navigate(
        `/restaurant/servers/summary?${params.toString()}`,
        { replace: true }
      );
    }
  }, [selectedDate, selectedLocation, navigate, isAdvancedMode]);

  // Fetch locations
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const sql = `
          SELECT id, name 
          FROM location 
          ORDER BY name ASC
        `;

        const response = await fetch(
          `https://${RESTAURANT_BACKEND_HOST}/admin/execute-select?sql=${encodeURIComponent(sql)}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch locations');
        }

        const data = await response.json();
        setLocations(data.results);

        // Set the first location as the default selected location only if we don't have a URL parameter
        if (data.results.length > 0 && !selectedLocation) {
          const locationToSelect = urlLocationId || data.results[0].id;
          setSelectedLocation(locationToSelect);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An unknown error occurred');
        console.error('Error fetching locations:', err);
      }
    };

    fetchLocations();
  }, []);

  // Fetch server data based on selected date and location
  useEffect(() => {
    const fetchServerData = async () => {
      if (!selectedLocation) return;

      setIsLoading(true);
      try {
        // Use timezone conversion in the SQL query to handle date properly
        // This matches the approach used in RecentShiftsTable
        const shiftsQuery = `
          SELECT 
            s.id as shift_id,
            s.server_id,
            sv.name as server_name,
            s.created as shift_created,
            CASE
              WHEN MAX(tp.created) > s.ended OR s.ended IS NULL 
              THEN MAX(tp.created)
              ELSE s.ended
            END as shift_ended,
            s.summary_text,
            s.summary_json,
            COUNT(tp.id) as fragments
          FROM shift s
          JOIN server sv ON s.server_id = sv.id
          LEFT JOIN transcript_part tp ON tp.shift_id = s.id
          WHERE sv.location_id = "${selectedLocation}"
            AND DATE(CONVERT_TZ(s.created, 'UTC', 'US/Pacific')) = "${selectedDate}"
          GROUP BY s.id, s.server_id, sv.name, s.created, s.ended, s.summary_text, s.summary_json
          HAVING COUNT(tp.id) >= ${MIN_FRAGMENTS}
          ORDER BY s.created ASC
        `;

        const shiftsResponse = await fetch(
          `https://${RESTAURANT_BACKEND_HOST}/admin/execute-select?sql=${encodeURIComponent(shiftsQuery)}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!shiftsResponse.ok) {
          throw new Error('Failed to fetch shifts data');
        }

        const shiftsData = await shiftsResponse.json();
        const shifts = shiftsData.results;

        if (shifts.length === 0) {
          setServerSummaries([]);
          setIsLoading(false);
          return;
        }

        // Get all shift IDs to use in the interactions query
        const shiftIds = shifts.map((shift: ShiftData) => `'${shift.shift_id}'`).join(',');

        // Single efficient query that gets interactions for the filtered shifts
        const interactionsQuery = `
          SELECT 
            shift_id,
            id as interaction_id,
            data as interaction_data
          FROM shift_interaction
          WHERE shift_id IN (${shiftIds})
        `;

        const interactionsResponse = await fetch(
          `https://${RESTAURANT_BACKEND_HOST}/admin/execute-select?sql=${encodeURIComponent(interactionsQuery)}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!interactionsResponse.ok) {
          throw new Error('Failed to fetch interaction data');
        }

        const interactionsData = await interactionsResponse.json();

        // Check the data format of the first interaction
        if (interactionsData.results.length > 0) {
          const firstInteraction = interactionsData.results[0];
          console.log('First interaction data type:', typeof firstInteraction.interaction_data);
          console.log('First interaction data sample:', firstInteraction.interaction_data);
        }

        const interactions = interactionsData.results;

        // Group interactions by shift_id
        const interactionsByShift = interactions.reduce((acc: { [key: string]: any[] }, interaction: any) => {
          if (!acc[interaction.shift_id]) {
            acc[interaction.shift_id] = [];
          }
          acc[interaction.shift_id].push(interaction);
          return acc;
        }, {});

        // Process each shift's data to create summaries
        const summaries: ServerSummary[] = shifts.map((shift: ShiftData) => {
          // Get interactions for this shift
          const shiftInteractions = interactionsByShift[shift.shift_id] || [];

          // Calculate shift duration in hours and minutes
          let shiftDuration = 0;
          if (shift.shift_created && shift.shift_ended) {
            const startTime = new Date(shift.shift_created.endsWith('Z')
              ? shift.shift_created
              : `${shift.shift_created}Z`);

            const endTime = new Date(shift.shift_ended.endsWith('Z')
              ? shift.shift_ended
              : `${shift.shift_ended}Z`);

            // Calculate difference in milliseconds and convert to minutes
            const diffMs = endTime.getTime() - startTime.getTime();
            shiftDuration = Math.round(diffMs / 60000); // Convert to minutes
          }

          // Count total interactions
          const totalInteractions = shiftInteractions.length;

          // Initialize criteria counters
          let upsellFulfilled = 0;
          let mentionFulfilled = 0;
          let describeFulfilled = 0;

          // Process each interaction
          shiftInteractions.forEach((interaction: any) => {
            if (interaction.interaction_data) {
              try {
                // The data might already be an object or it might be a JSON string
                let data: any;

                if (typeof interaction.interaction_data === 'string') {
                  // If it's a string, parse it
                  data = JSON.parse(interaction.interaction_data);
                } else {
                  // If it's already an object, use it directly
                  data = interaction.interaction_data;
                }

                // Debug log to inspect the structure of the first interaction for this shift
                if (shiftInteractions.indexOf(interaction) === 0) {
                  console.log(`First interaction for shift ${shift.shift_id}:`, {
                    interactionId: interaction.interaction_id,
                    dataType: typeof data,
                    hasServiceCriteria: !!data?.service_criteria,
                    serviceCriteriaType: data?.service_criteria ? typeof data.service_criteria : 'N/A',
                    isArray: data?.service_criteria ? Array.isArray(data.service_criteria) : 'N/A',
                    length: data?.service_criteria ? data.service_criteria.length : 'N/A',
                    sample: data?.service_criteria && Array.isArray(data.service_criteria) ? data.service_criteria[0] : null
                  });
                }

                if (data && data.service_criteria && Array.isArray(data.service_criteria)) {
                  const criteria = data.service_criteria;

                  // Find each criterion by type and check if it's fulfilled
                  const upsellCriterion = criteria.find((c: any) => c.type === 'upsell_premium_menu');
                  if (upsellCriterion && upsellCriterion.fulfilled === true) {
                    upsellFulfilled++;
                  }

                  const mentionCriterion = criteria.find((c: any) => c.type === 'mention_specific_premium_item');
                  if (mentionCriterion && mentionCriterion.fulfilled === true) {
                    mentionFulfilled++;
                  }

                  const describeCriterion = criteria.find((c: any) => c.type === 'describe_specific_premium_item');
                  if (describeCriterion && describeCriterion.fulfilled === true) {
                    describeFulfilled++;
                  }
                }
              } catch (e) {
                console.error('Error processing interaction data:', e);
                // Try handling it as a direct object if JSON parsing failed
                try {
                  const data = interaction.interaction_data;
                  if (data && data.service_criteria && Array.isArray(data.service_criteria)) {
                    const criteria = data.service_criteria;

                    const upsellCriterion = criteria.find((c: any) => c.type === 'upsell_premium_menu');
                    if (upsellCriterion && upsellCriterion.fulfilled === true) {
                      upsellFulfilled++;
                    }

                    const mentionCriterion = criteria.find((c: any) => c.type === 'mention_specific_premium_item');
                    if (mentionCriterion && mentionCriterion.fulfilled === true) {
                      mentionFulfilled++;
                    }

                    const describeCriterion = criteria.find((c: any) => c.type === 'describe_specific_premium_item');
                    if (describeCriterion && describeCriterion.fulfilled === true) {
                      describeFulfilled++;
                    }
                  }
                } catch (secondError) {
                  console.error('Failed to process interaction data as object:', secondError);
                }
              }
            }
          });

          console.log(`Shift ${shift.shift_id}:`, {
            totalInteractions,
            upsellFulfilled,
            mentionFulfilled,
            describeFulfilled
          });

          // Create the summary object
          return {
            serverId: shift.server_id,
            serverName: shift.server_name,
            shiftId: shift.shift_id,
            shiftStarted: shift.shift_created,
            shiftEnded: shift.shift_ended,
            shiftDuration: shiftDuration,
            totalInteractions: totalInteractions,
            fragments: shift.fragments || 0,
            summaryText: shift.summary_text,
            summaryJson: shift.summary_json || null,
            criteriaStats: {
              upsellPremiumMenu: upsellFulfilled,
              mentionSpecificPremiumItem: mentionFulfilled,
              describeSpecificPremiumItem: describeFulfilled
            }
          };
        });

        setServerSummaries(summaries);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An unknown error occurred');
        console.error('Error fetching server data:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchServerData();
  }, [selectedDate, selectedLocation]);

  // Format date for display
  const formatDate = (dateString: string | null) => {
    if (!dateString) return 'N/A';

    // Ensure the date string is treated as UTC
    const utcDateString = dateString.endsWith('Z') ? dateString : `${dateString}Z`;
    const date = new Date(utcDateString);

    return format(date, 'h:mm a');
  };

  // Format date with day for display
  const formatDateWithDay = (dateString: string | null) => {
    if (!dateString) return 'N/A';

    // Ensure the date string is treated as UTC
    const utcDateString = dateString.endsWith('Z') ? dateString : `${dateString}Z`;
    const date = new Date(utcDateString);

    return format(date, 'MMM d, yyyy h:mm a');
  };

  // Format date for title
  const formatDateForTitle = (dateString: string) => {
    try {
      // Use the date string directly instead of creating a new Date object
      // This prevents timezone conversion issues when formatting
      const [year, month, day] = dateString.split('-').map(Number);

      // Create a formatted date without timezone conversion
      const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];

      return `${months[month - 1]} ${day}, ${year}`;
    } catch (e) {
      return dateString;
    }
  };

  // Get location name from ID
  const getLocationName = (locationId: string): string => {
    const location = locations.find(loc => loc.id === locationId);
    return location ? location.name : 'Unknown Location';
  };

  // Format percentage
  const formatPercentage = (value: number, total: number) => {
    if (total === 0) return '0%';
    return `${Math.round((value / total) * 100)}%`;
  };

  // Remove the "Server Feedback Session" header from markdown if present
  const preprocessSummaryText = (text: string | null): string => {
    if (!text) return '';

    // Remove "# Server Feedback Session" (with any number of # characters)
    return text.replace(/^#+\s*Server\s+Feedback\s+Session\s*$/m, '').trim();
  };

  // Format duration in hours and minutes
  const formatDuration = (minutes: number): string => {
    if (minutes <= 0) return 'N/A';

    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    return `${hours > 0 ? `${hours}h ` : ''}${mins}m`;
  };

  const handleRowClick = (serverId: string, shiftId: string) => {
    if (isAdvancedMode) {
      navigate(`/restaurant/shifts/${shiftId}/transcript/${serverId}`);
    }
  };

  // Function to open the modal with interaction details
  const openInteractionDetails = (interaction: any, showFullQuoteOnly: boolean = false) => {
    if (!interaction) return;

    if (showFullQuoteOnly) {
      // For "Full Quote" mode, only include the text and empty fields for the rest
      setSelectedInteraction({
        text: interaction.text,
        reasoning: "", // Empty reasoning field
        service_criteria: [] // Empty service criteria
      });
    } else {
      // Regular mode with all details
      setSelectedInteraction({
        text: interaction.text,
        reasoning: interaction.reasoning,
        service_criteria: interaction.interaction?.service_criteria || []
      });
    }

    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedInteraction(null);
  };

  // Helper function to format service criteria type for display
  const formatCriteriaType = (type: string): string => {
    switch (type) {
      case 'upsell_premium_menu':
        return 'Upsell Premium Menu';
      case 'mention_specific_premium_item':
        return 'Mention Specific Premium Item';
      case 'describe_specific_premium_item':
        return 'Describe Specific Premium Item';
      default:
        return type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }
  };

  // Helper function to format service criteria as emojis, similar to transcript_annotator
  const formatCriteriaEmojis = (criteria: {
    upsellPremiumMenu: number,
    mentionSpecificPremiumItem: number,
    describeSpecificPremiumItem: number
  }, total: number): string => {
    if (total === 0) return "";

    const criteriaMap = {
      upsellPremiumMenu: "💰",
      mentionSpecificPremiumItem: "🥩",
      describeSpecificPremiumItem: "🗣️"
    };

    // Format emoji with checkmark for fulfilled, dash for not fulfilled
    const feedbackParts = Object.entries(criteriaMap).map(([key, emoji]) => {
      const typedKey = key as keyof typeof criteria;
      // Simple binary check - if criteria is used at all, it's a checkmark, otherwise dash
      return `${emoji}${criteria[typedKey] > 0 ? "✅" : "➖"}`;
    });

    return feedbackParts.join("  ");
  };

  // Helper function to format criteria emojis for a specific interaction
  const formatInteractionCriteriaEmojis = (serviceCriteria: Array<{ type: string, fulfilled: boolean }> | undefined): string => {
    if (!serviceCriteria || serviceCriteria.length === 0) return "";

    const criteriaMap = {
      upsell_premium_menu: "💰",
      mention_specific_premium_item: "🥩",
      describe_specific_premium_item: "🗣️"
    };

    // Create a lookup map for criteria by type
    const criteriaLookup = serviceCriteria.reduce((acc, criterion) => {
      acc[criterion.type] = criterion.fulfilled;
      return acc;
    }, {} as Record<string, boolean>);

    const feedbackParts = Object.entries(criteriaMap).map(([criterionType, emoji]) => {
      // Check if this criterion exists and is fulfilled
      return `${emoji}${criteriaLookup[criterionType] ? "✅" : "➖"}`;
    });

    return feedbackParts.join("  ");
  };

  // Get a random server hint based on the shift ID (consistent for same shift)
  const getServerHint = (shiftId: string): string => {
    // Server hints array
    const serverHints = [
      "Learn the complete menu inside and out, especially premium cuts and their unique qualities.",
      "Begin with a brief explanation of the Korean BBQ experience for first-time guests.",
      "Highlight signature or seasonal drink menu items immediately after greeting.",
      "When guests are deciding, suggest: \"Many of our regular guests start with the Premium ribeye® and Premium wagyu® to compare the flavor profiles.\"",
      "For hesitant guests, offer: \"Would you like to add just one Premium wagyu ala carte to your order to experience the difference?\"",
      "Create urgency with phrases like: \"Our Premium marbling center cut marinated short rib is limited each day and often sells out.\"",
      "For large groups, suggest premium items that can be shared for a more economical way to experience luxury cuts.",
      "Use descriptive language when describing premium items: \"The Carne asada has a perfect char on the outside while remaining juicy inside.\"",
      "For groups celebrating special occasions, suggest premium platters as \"celebration-worthy\" selections.",
      "Our Premium marinated long bone short rib steak® is limited each day - we've only got a few portions left tonight.",
      "Based on what you've ordered, I think you'd really enjoy our Premium wagyu® - it has similar flavors but more intense marbling.",
      "Kimchi fried rice® pairs perfectly with our Premium wagyu® - they're designed to complement each other.",
      "For large groups mention that an ala carte premium item is only a few dollars each.",
      "Our Premium long bone short rib steak® makes for an amazing photo opportunity - it's our most Instagrammed dish.",
      "Our Cajun shrimp® is an exclusive premium item you won't find at other Korean BBQ restaurants.",
      "Before I take your food order, let me tell you about our seasonal cocktails that perfectly complement our premium meats.",
      "Before we start with food, have you tried our new craft cocktails? They've just been released this week and go great with our Premium wagyu®"
    ];

    // Use a simple hash function to get a consistent index based on shift ID
    let hash = 0;
    for (let i = 0; i < shiftId.length; i++) {
      hash = ((hash << 5) - hash) + shiftId.charCodeAt(i);
      hash = hash & hash; // Convert to 32bit integer
    }

    // Make sure it's positive and within bounds of the array
    const index = Math.abs(hash) % serverHints.length;
    return serverHints[index];
  };

  // Function to extract a snippet from text
  const extractSnippet = (text: string, maxLength: number = 120): string => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength).trim() + "...";
  };

  if (isLoading && !serverSummaries.length) {
    return <FullPageSpinner />;
  }

  if (error && !serverSummaries.length) {
    return <div className="text-red-500 p-4">Error: {error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-6 max-w-7xl">
      <h1 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6">
        {selectedDate && selectedLocation ? (
          `${formatDateForTitle(selectedDate)} ${getLocationName(selectedLocation)} Server Performance Summary`
        ) : (
          "Server Performance Summary"
        )}
      </h1>

      <div className="bg-white shadow-md rounded-lg p-4 md:p-6 mb-6">
        {/* Only show the filters in advanced mode */}
        {isAdvancedMode && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div>
              <label htmlFor="date-select" className="block text-sm font-medium text-gray-700 mb-1">
                Select Date
              </label>
              <input
                id="date-select"
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label htmlFor="location-select" className="block text-sm font-medium text-gray-700 mb-1">
                Select Location
              </label>
              <select
                id="location-select"
                value={selectedLocation}
                onChange={(e) => setSelectedLocation(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Select a location</option>
                {locations.map((location) => (
                  <option key={location.id} value={location.id}>
                    {location.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        {serverSummaries.length === 0 ? (
          <div className="text-center py-8">
            <p className="text-gray-500">No shifts found for the selected date and location with at least {MIN_FRAGMENTS} fragments.</p>
          </div>
        ) : (
          <>
            {/* Desktop view - Table */}
            <div className="hidden md:block overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[15%]">
                      Server
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[15%]">
                      Key Metrics
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[70%]">
                      Summary
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {serverSummaries.map((summary) => (
                    <tr
                      key={summary.shiftId}
                      className={isAdvancedMode ? "hover:bg-blue-50 cursor-pointer transition-colors duration-150" : ""}
                      onClick={() => isAdvancedMode && handleRowClick(summary.serverId, summary.shiftId)}
                      title={isAdvancedMode ? "Click to view transcript" : ""}
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className={`text-md font-medium ${isAdvancedMode ? "text-blue-600" : "text-gray-900"}`}>
                          {summary.serverName}
                          {isAdvancedMode && (
                            <span className="ml-1 text-xs text-blue-500">→</span>
                          )}
                        </div>
                        <div className="text-xs text-gray-500">{formatDateWithDay(summary.shiftStarted)}</div>
                        {summary.shiftDuration > 0 && (
                          <div className="text-xs text-gray-500">Duration: {formatDuration(summary.shiftDuration)}</div>
                        )}
                        <div className="text-xs text-gray-500 mt-1">Interactions: {summary.totalInteractions}</div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="space-y-3">
                          {/* Compact metrics with mini progress bars */}
                          <div>
                            <div className="flex justify-between items-center mb-1">
                              <span className="text-xs font-medium">Premium Menu</span>
                              <span className="text-xs font-medium">{formatPercentage(summary.criteriaStats.upsellPremiumMenu, summary.totalInteractions)}</span>
                            </div>
                            <div className="w-full bg-gray-200 rounded-full h-1.5">
                              <div
                                className="bg-blue-600 h-1.5 rounded-full"
                                style={{ width: `${summary.totalInteractions > 0 ? (summary.criteriaStats.upsellPremiumMenu / summary.totalInteractions * 100) : 0}%` }}
                              ></div>
                            </div>
                          </div>

                          <div>
                            <div className="flex justify-between items-center mb-1">
                              <span className="text-xs font-medium">Item Mention</span>
                              <span className="text-xs font-medium">{formatPercentage(summary.criteriaStats.mentionSpecificPremiumItem, summary.totalInteractions)}</span>
                            </div>
                            <div className="w-full bg-gray-200 rounded-full h-1.5">
                              <div
                                className="bg-green-500 h-1.5 rounded-full"
                                style={{ width: `${summary.totalInteractions > 0 ? (summary.criteriaStats.mentionSpecificPremiumItem / summary.totalInteractions * 100) : 0}%` }}
                              ></div>
                            </div>
                          </div>

                          <div>
                            <div className="flex justify-between items-center mb-1">
                              <span className="text-xs font-medium">Item Description</span>
                              <span className="text-xs font-medium">{formatPercentage(summary.criteriaStats.describeSpecificPremiumItem, summary.totalInteractions)}</span>
                            </div>
                            <div className="w-full bg-gray-200 rounded-full h-1.5">
                              <div
                                className="bg-purple-500 h-1.5 rounded-full"
                                style={{ width: `${summary.totalInteractions > 0 ? (summary.criteriaStats.describeSpecificPremiumItem / summary.totalInteractions * 100) : 0}%` }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-700">
                        {summary.summaryJson ? (
                          <div className="space-y-4">
                            {/* Good Interaction - Compact view */}
                            {summary.summaryJson.good_interaction && (
                              <div className="border-l-4 border-green-500 pl-3 py-2">
                                <h5 className="font-medium text-green-700 mb-2 text-sm">Good Interaction Example</h5>
                                {/* Criteria emojis for this good interaction */}
                                <div className="text-lg mb-2">
                                  {formatInteractionCriteriaEmojis(summary.summaryJson.good_interaction.interaction.service_criteria)}
                                </div>
                                <p className="text-sm text-gray-700 mb-2">
                                  {extractSnippet(summary.summaryJson.good_interaction.text)}
                                </p>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openInteractionDetails(summary.summaryJson?.good_interaction, true);
                                  }}
                                  className="text-xs bg-green-100 hover:bg-green-200 text-green-800 font-medium py-1 px-2 rounded transition"
                                >
                                  Full Quote
                                </button>
                              </div>
                            )}

                            {/* Bad Interaction - Compact view */}
                            {summary.summaryJson.bad_interaction && (
                              <div className="border-l-4 border-red-500 pl-3 py-2">
                                <h5 className="font-medium text-red-700 mb-2 text-sm">Improvement Opportunity</h5>
                                {/* Criteria emojis for this bad interaction */}
                                <div className="text-lg mb-2">
                                  {formatInteractionCriteriaEmojis(summary.summaryJson.bad_interaction.interaction.service_criteria)}
                                </div>
                                <p className="text-sm text-gray-700 mb-2">
                                  {extractSnippet(summary.summaryJson.bad_interaction.text)}
                                </p>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openInteractionDetails(summary.summaryJson?.bad_interaction, true);
                                  }}
                                  className="text-xs bg-red-100 hover:bg-red-200 text-red-800 font-medium py-1 px-2 rounded transition"
                                >
                                  Full Quote
                                </button>
                              </div>
                            )}

                            {/* Server Hint/Tip for desktop view */}
                            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-3">
                              <h5 className="text-sm font-medium text-yellow-800 mb-1">Tip for improvement:</h5>
                              <p className="text-sm text-yellow-700">{getServerHint(summary.shiftId)}</p>
                            </div>

                            {!summary.summaryJson.good_interaction && !summary.summaryJson.bad_interaction && (
                              <div className="text-gray-500 italic">No interaction examples available</div>
                            )}
                          </div>
                        ) : summary.summaryText ? (
                          <div className="space-y-4">
                            <div className="prose prose-sm max-w-none">
                              <ReactMarkdown className="break-words">
                                {preprocessSummaryText(summary.summaryText)}
                              </ReactMarkdown>
                            </div>

                            {/* Server Hint/Tip for desktop view with text summary */}
                            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-3 mt-4">
                              <h5 className="text-sm font-medium text-yellow-800 mb-1">Tip for improvement:</h5>
                              <p className="text-sm text-yellow-700">{getServerHint(summary.shiftId)}</p>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <span className="text-gray-400">No summary available</span>

                            {/* Server Hint/Tip for desktop view with no summary */}
                            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-3 mt-4">
                              <h5 className="text-sm font-medium text-yellow-800 mb-1">Tip for improvement:</h5>
                              <p className="text-sm text-yellow-700">{getServerHint(summary.shiftId)}</p>
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Mobile view - Cards */}
            <div className="md:hidden space-y-6">
              {serverSummaries.map((summary) => (
                <div
                  key={summary.shiftId}
                  className={`bg-white border rounded-lg shadow-sm overflow-hidden ${isAdvancedMode ? "active:bg-blue-50" : ""}`}
                  onClick={() => isAdvancedMode && handleRowClick(summary.serverId, summary.shiftId)}
                >
                  {/* Server Info Header */}
                  <div className="p-4 border-b bg-gray-50">
                    <div className="flex justify-between items-center">
                      <div>
                        <h3 className={`text-lg font-medium ${isAdvancedMode ? "text-blue-600" : "text-gray-900"}`}>
                          {summary.serverName}
                          {isAdvancedMode && <span className="ml-1 text-xs text-blue-500">→</span>}
                        </h3>
                        <p className="text-xs text-gray-500">{formatDateWithDay(summary.shiftStarted)}</p>
                        <div className="flex items-center space-x-3 mt-1">
                          <span className="text-xs text-gray-500">
                            {summary.shiftDuration > 0 ? `Duration: ${formatDuration(summary.shiftDuration)}` : ''}
                          </span>
                          <span className="text-xs text-gray-500">
                            Interactions: {summary.totalInteractions}
                          </span>
                        </div>
                      </div>
                      <button
                        onClick={(e) => toggleCardExpansion(summary.shiftId, e)}
                        className="text-gray-500 hover:text-gray-700"
                        aria-label={expandedCards.has(summary.shiftId) ? "Collapse details" : "Expand details"}
                      >
                        <svg
                          className={`w-6 h-6 transition-transform ${expandedCards.has(summary.shiftId) ? 'transform rotate-180' : ''}`}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                      </button>
                    </div>
                  </div>

                  {/* Metrics Section */}
                  <div className="p-4 border-b">
                    <h4 className="text-sm font-medium text-gray-700 mb-3">Performance Metrics</h4>
                    <div className="space-y-3">
                      <div>
                        <div className="flex justify-between items-center mb-1">
                          <span className="text-xs font-medium">Premium Menu</span>
                          <span className="text-xs font-medium">{formatPercentage(summary.criteriaStats.upsellPremiumMenu, summary.totalInteractions)}</span>
                        </div>
                        <div className="w-full bg-gray-200 rounded-full h-2">
                          <div
                            className="bg-blue-600 h-2 rounded-full"
                            style={{ width: `${summary.totalInteractions > 0 ? (summary.criteriaStats.upsellPremiumMenu / summary.totalInteractions * 100) : 0}%` }}
                          ></div>
                        </div>
                      </div>

                      <div>
                        <div className="flex justify-between items-center mb-1">
                          <span className="text-xs font-medium">Item Mention</span>
                          <span className="text-xs font-medium">{formatPercentage(summary.criteriaStats.mentionSpecificPremiumItem, summary.totalInteractions)}</span>
                        </div>
                        <div className="w-full bg-gray-200 rounded-full h-2">
                          <div
                            className="bg-green-500 h-2 rounded-full"
                            style={{ width: `${summary.totalInteractions > 0 ? (summary.criteriaStats.mentionSpecificPremiumItem / summary.totalInteractions * 100) : 0}%` }}
                          ></div>
                        </div>
                      </div>

                      <div>
                        <div className="flex justify-between items-center mb-1">
                          <span className="text-xs font-medium">Item Description</span>
                          <span className="text-xs font-medium">{formatPercentage(summary.criteriaStats.describeSpecificPremiumItem, summary.totalInteractions)}</span>
                        </div>
                        <div className="w-full bg-gray-200 rounded-full h-2">
                          <div
                            className="bg-purple-500 h-2 rounded-full"
                            style={{ width: `${summary.totalInteractions > 0 ? (summary.criteriaStats.describeSpecificPremiumItem / summary.totalInteractions * 100) : 0}%` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Summary Section - Expandable on mobile */}
                  <div className={`${expandedCards.has(summary.shiftId) ? 'block' : 'hidden'} p-4`}>
                    <h4 className="text-sm font-medium text-gray-700 mb-3">Performance Summary</h4>

                    {summary.summaryJson ? (
                      <div className="space-y-4">
                        {/* Good Interaction - Compact view */}
                        {summary.summaryJson.good_interaction && (
                          <div className="border-l-4 border-green-500 pl-3 py-2">
                            <h5 className="font-medium text-green-700 mb-2 text-sm">Good Interaction Example</h5>
                            {/* Criteria emojis for this good interaction */}
                            <div className="text-lg mb-2">
                              {formatInteractionCriteriaEmojis(summary.summaryJson.good_interaction.interaction.service_criteria)}
                            </div>
                            <p className="text-sm text-gray-700 mb-2">
                              {extractSnippet(summary.summaryJson.good_interaction.text)}
                            </p>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                openInteractionDetails(summary.summaryJson?.good_interaction, true);
                              }}
                              className="text-xs bg-green-100 hover:bg-green-200 text-green-800 font-medium py-1 px-2 rounded transition"
                            >
                              Full Quote
                            </button>
                          </div>
                        )}

                        {/* Bad Interaction - Compact view */}
                        {summary.summaryJson.bad_interaction && (
                          <div className="border-l-4 border-red-500 pl-3 py-2">
                            <h5 className="font-medium text-red-700 mb-2 text-sm">Improvement Opportunity</h5>
                            {/* Criteria emojis for this bad interaction */}
                            <div className="text-lg mb-2">
                              {formatInteractionCriteriaEmojis(summary.summaryJson.bad_interaction.interaction.service_criteria)}
                            </div>
                            <p className="text-sm text-gray-700 mb-2">
                              {extractSnippet(summary.summaryJson.bad_interaction.text)}
                            </p>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                openInteractionDetails(summary.summaryJson?.bad_interaction, true);
                              }}
                              className="text-xs bg-red-100 hover:bg-red-200 text-red-800 font-medium py-1 px-2 rounded transition"
                            >
                              Full Quote
                            </button>
                          </div>
                        )}

                        {/* Server Hint/Tip for desktop view */}
                        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-3">
                          <h5 className="text-sm font-medium text-yellow-800 mb-1">Tip for improvement:</h5>
                          <p className="text-sm text-yellow-700">{getServerHint(summary.shiftId)}</p>
                        </div>

                        {!summary.summaryJson.good_interaction && !summary.summaryJson.bad_interaction && (
                          <div className="text-gray-500 italic">No interaction examples available</div>
                        )}
                      </div>
                    ) : summary.summaryText ? (
                      <div className="space-y-4">
                        <div className="prose prose-sm max-w-none">
                          <ReactMarkdown className="break-words">
                            {preprocessSummaryText(summary.summaryText)}
                          </ReactMarkdown>
                        </div>

                        {/* Server Hint/Tip for desktop view with text summary */}
                        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-3 mt-4">
                          <h5 className="text-sm font-medium text-yellow-800 mb-1">Tip for improvement:</h5>
                          <p className="text-sm text-yellow-700">{getServerHint(summary.shiftId)}</p>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <span className="text-gray-400">No summary available</span>

                        {/* Server Hint/Tip for desktop view with no summary */}
                        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-3 mt-4">
                          <h5 className="text-sm font-medium text-yellow-800 mb-1">Tip for improvement:</h5>
                          <p className="text-sm text-yellow-700">{getServerHint(summary.shiftId)}</p>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* "View Summary" button when card is collapsed */}
                  {!expandedCards.has(summary.shiftId) && (
                    <div className="p-3 border-t bg-gray-50 text-center">
                      <button
                        onClick={(e) => toggleCardExpansion(summary.shiftId, e)}
                        className="text-sm text-blue-600 font-medium hover:text-blue-800"
                      >
                        View Performance Summary
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      {/* Modal for showing interaction details */}
      {isModalOpen && selectedInteraction && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50 p-4" onClick={closeModal}>
          <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto" onClick={e => e.stopPropagation()}>
            <div className="p-4 md:p-6">
              <div className="flex justify-between items-start">
                <h3 className="text-lg md:text-xl font-bold text-gray-900 mb-4">Full Quote</h3>
                <button onClick={closeModal} className="text-gray-400 hover:text-gray-500">
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div className="mb-6">
                <div className="bg-gray-50 p-4 rounded-md text-sm">
                  {selectedInteraction.text}
                </div>
              </div>

              {selectedInteraction.service_criteria && selectedInteraction.service_criteria.length > 0 && (
                <div className="mb-6">
                  <h4 className="font-medium text-gray-700 mb-2">Service Criteria Results</h4>
                  <div className="space-y-2">
                    {selectedInteraction.service_criteria.map((criteria, index) => (
                      <div key={index} className="flex items-center">
                        <div className={`w-5 h-5 rounded-full flex items-center justify-center mr-3 ${criteria.fulfilled ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                          {criteria.fulfilled ? (
                            <svg className="w-3 h-3" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                            </svg>
                          ) : (
                            <svg className="w-3 h-3" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                          )}
                        </div>
                        <span className={`${criteria.fulfilled ? 'text-green-800' : 'text-red-800'}`}>
                          {formatCriteriaType(criteria.type)}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {selectedInteraction.reasoning && (
                <div>
                  <h4 className="font-medium text-gray-700 mb-2">Analysis</h4>
                  <div className="bg-gray-50 p-4 rounded-md text-sm">
                    {selectedInteraction.reasoning}
                  </div>
                </div>
              )}
            </div>

            <div className="bg-gray-50 px-4 md:px-6 py-3 flex justify-end">
              <button
                onClick={closeModal}
                className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded transition"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServerSummaryView;