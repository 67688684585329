import React, { useState } from 'react';
import GradientBespoke from './GradientBespoke';
import FullPageSpinner from './FullPageSpinner';
import api from '../api';

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const handleGoogleLogin = async (): Promise<void> => {
    setLoading(true);
    window.location.href = await api.getGoogleAuthorizationUrl();
  };

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-blue-50 to-white">
      {/* Hero Section */}
      <section className="relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="pt-20 pb-24 md:pt-24 md:pb-32">
            <div className="text-center pb-12 md:pb-16 space-y-8">
              <div className="animate-fade-in">
                <div className="flex items-center justify-center mb-6 space-x-4">
                  <img className="h-24 w-auto drop-shadow-lg" src="img/main-logo.png" alt="Bespoke logo" />
                  <GradientBespoke className="h-16" />
                </div>
                <h1 className="text-5xl sm:text-6xl lg:text-7xl font-bold leading-tight mb-6 bg-gradient-to-r from-slate-800 to-slate-700 bg-clip-text text-transparent">
                  Transform Restaurant Management<br />
                  <span className="text-4xl sm:text-5xl lg:text-6xl">with AI-Powered Tools</span>
                </h1>
                <div className="max-w-3xl mx-auto">
                  <p className="text-xl text-slate-600 mb-12 leading-relaxed">
                    Managing a restaurant efficiently shouldn't be overwhelming. Bespoke's AI-powered platform helps you optimize break schedules, streamline employee training, and monitor performance in real-time.
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-4 max-w-4xl mx-auto" data-aos="fade-up">
              <div className="relative rounded-[2.5rem] overflow-hidden shadow-2xl border-8 border-white rotate-1 hover:rotate-0 transition-transform duration-300">
                <img className="w-full h-full object-cover" src="img/server.png" alt="Dashboard Preview" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {[
              {
                icon: '⏱️',
                title: 'Smart Break Management',
                description: 'AI-optimized break scheduling that balances staff needs with operational demands'
              },
              {
                icon: '📈',
                title: 'Training & Performance',
                description: 'Real-time performance tracking and personalized training recommendations'
              }
            ].map((card, idx) => (
              <div key={idx} className="group bg-white p-8 rounded-3xl border border-gray-100 hover:border-transparent hover:shadow-2xl transition-all duration-300">
                <div className="text-4xl mb-6 bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
                  {card.icon}
                </div>
                <h3 className="text-2xl font-bold text-slate-800 mb-4 group-hover:text-blue-600 transition-colors">
                  {card.title}
                </h3>
                <p className="text-lg text-slate-600 leading-relaxed">
                  {card.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Founders Section */}
      <section className="py-16 bg-slate-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <h2 className="text-3xl sm:text-4xl font-extrabold text-center mb-12 text-slate-800">Meet the Founders</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Eric Chung - CEO */}
            <div className="flex flex-col items-center text-center p-8 bg-white rounded-lg shadow-lg">
              <img
                src="img/eric.png"
                alt="Eric Chung"
                className="w-40 h-40 rounded-full object-cover mb-6 border-4 border-slate-200"
              />
              <h3 className="text-2xl font-bold text-gray-900">Eric Chung</h3>
              <p className="text-blue-700 font-semibold mb-4">CEO & Co-founder</p>
              <p className="text-gray-600 text-lg">
                Former Coinbase executive and restaurant industry veteran. Passionate about revolutionizing restaurant operations through AI-powered solutions.
              </p>
            </div>

            {/* Jesse Hull - CTO */}
            <div className="flex flex-col items-center text-center p-8 bg-white rounded-lg shadow-lg">
              <img
                src="img/jesse.png"
                alt="Jesse Hull"
                className="w-40 h-40 rounded-full object-cover mb-6 border-4 border-slate-200"
              />
              <h3 className="text-2xl font-bold text-gray-900">Jesse Hull</h3>
              <p className="text-blue-700 font-semibold mb-4">CTO & Co-founder</p>
              <p className="text-gray-600 text-lg">
                AI and automation expert with a focus on developing practical solutions for the restaurant industry.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-white mt-10 py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 text-center text-gray-500">
          <a href="/privacy.html" className="hover:text-gray-700 mr-4">Privacy</a>
          <a href="/terms.html" className="hover:text-gray-700">Terms</a>
        </div>
      </footer>
    </div>
  );
};

export default Login;